import React from 'react'
import './AboutAdvantage.css'

const MobileAdvantage = () => {
    return (
        <>
            <section className='about-advantage'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mb-md-5 mb-3'>
                            <div className='title-profcyma-help mt-5 text-center'>
                                <h2 className='title'>Advantages of taking admission from Profcyma</h2>
                                <p>Why Enroll In Online Learning with Profcyma</p>
                            </div>
                        </div>

                        {/* First row */}
                        <div className='col-2 text-center'>
                            <div className='orange-main-class'>
                                <div className='white-bg-class'>
                                    <p className='number-class'>1</p>
                                </div>
                            </div>

                            <div className='middle-orange-line'></div>
                        </div>
                        <div className='col-10'>
                            <div className='about-title'>
                                <h2><span>W</span>hat is profcyma? </h2>
                                <p>Premier destination for superior online education,
                                    empowering working professionals with top-tier
                                    courses and expertise.</p>
                            </div>
                        </div>
                        {/* second row */}
                        <div className='col-2 text-center'>
                            <div className='orange-main-class'>
                                <div className='white-bg-class'>
                                    <p className='number-class'>2</p>
                                </div>
                            </div>

                            <div className='middle-orange-line'></div>
                        </div>
                        <div className='col-10'>
                            <div className='about-title'>
                                <h2><span>W</span>ho are graduates?</h2>
                                <p>Profcyma graduates encompass a thriving community of 50,000+ satisfied students, shaped through our expert guidance.</p>
                            </div>
                        </div>
                        {/* Third row */}
                        <div className='col-2 text-center'>
                            <div className='orange-main-class'>
                                <div className='white-bg-class'>
                                    <p className='number-class'>3</p>
                                </div>
                            </div>

                            <div className='middle-orange-line'></div>
                        </div>
                        <div className='col-10'>
                            <div className='about-title'>
                                <h2><span>W</span>hy should I choose?</h2>
                                <p>Choose Profcyma for 24/7 support, flexible learning, and career advancement with India's leading universities</p>
                            </div>
                        </div>
                        {/* Four row */}
                        <div className='col-2 text-center'>
                            <div className='orange-main-class'>
                                <div className='white-bg-class'>
                                    <p className='number-class'>4</p>
                                </div>
                            </div>

                            <div className='middle-orange-line'></div>
                        </div>
                        <div className='col-10'>
                            <div className='about-title'>
                                <h2><span>W</span>here do I have to study?</h2>
                                <p>Your premier choice for online courses and universities nationwide, enabling flexible study anytime, anywhere.</p>
                            </div>
                        </div>
                        {/* Fifth row */}
                        <div className='col-2 text-center'>
                            <div className='orange-main-class'>
                                <div className='white-bg-class'>
                                    <p className='number-class'>5</p>
                                </div>
                            </div>                            
                        </div>
                        <div className='col-10'>
                            <div className='about-title'>
                                <h2><span>H</span>ow to get a counselor?</h2>
                                <p>Choose Profcyma for 24/7 support, flexible learning, and career advancement with India's leading universities</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MobileAdvantage;