import React, { useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "./ProgrammOffered.css";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { ShimmerSectionHeader, ShimmerThumbnail } from "react-shimmer-effects";
import { Col, Container, Row } from "react-bootstrap";

const ProgrammOffered = ({ id }) => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);

  }, []);

  useEffect(() => {
    if (!isLoading) {
      getDataAll();
    }

  }, [isLoading, id]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/common/program-offered/${id}`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const groupedData = [];
  for (let i = 0; i < data.length; i += 4) {
    groupedData.push(data.slice(i, i + 4));
  }

  return (
    <>
      {isLoading &&
        <Container>
          <Row>
            <Col md={5} className="mt-5">
              <ShimmerSectionHeader />
            </Col>
            <Col md={7}>
              <ShimmerThumbnail height={450} rounded />
            </Col>
          </Row>
        </Container>
      }
      {!isLoading && data && data?.length > 0 &&
        <section className="programm-featured">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-4 my-auto">
                <div className="title-programm-offered">
                  <h2>
                    <span>Program</span> Offered
                  </h2>
                  <p>
                    Choose from various programs offered by us through the online
                    mode.
                  </p>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row programOffer">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 1,
                        spaceBetween: 50,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {groupedData.map((group, groupIndex) => (
                      <SwiperSlide key={groupIndex}>
                        <div className="row">
                          {group.map((val, index) => (
                            <div className="col-md-6 col-sm-6 my-2" key={val?.id}>
                              <Link
                                // to={`/ed-tech/courses/${val?.cource_university?.course_id}`}
                                to={`/ed-tech/courses/${val?.cource_university?.course_id}/${val.name.replace(/\s+/g, '-').toLowerCase()}`}
                              >
                                <div className="card cource-card">
                                  <div className="card-img-main">
                                    <img
                                      src={`${IMG_URL}${val?.image}`}
                                      className="card-img"
                                      alt={val?.name}
                                    />
                                  </div>
                                  <div className="card-title">
                                    <h3>{val?.name}</h3>
                                    <p>{parse(val?.description)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default ProgrammOffered;
