import React from "react";
import "./CareerPath.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

function CareerPath() {
  // Array of slide content
  const slideContent = [
    {
      imgSrc: "/assets/images/Blogs/Third-tab/img-1.png",
      title: "Embracing Continuous Growth in a Dynamic Business Landscape",
      text:
        "In the fast-paced and growing business environment, continuous skill development is imperative for professionals seeking to stay ahead. Online MBA programs play a pivotal role in equipping individuals with the skills needed to navigate the intricacies of modern business. The knowledge acquired during an online MBA goes beyond traditional boundaries, offering a dynamic and up-to-date curriculum that ensures graduates are well-prepared to meet the demands of the contemporary business world.",
    },
    {
      imgSrc: "/assets/images/Blogs/Third-tab/img-2.png",
      title: "Unlocking Networking Opportunities for Career Advancement",
      text:
        "An invaluable aspect of pursuing an online MBA is the wealth of networking opportunities it presents. Building robust personal and professional connections is crucial in the business realm, and an online MBA program facilitates interaction with a diverse group of ambitious professionals who may eventually become managers, entrepreneurs, or industry leaders. These connections can prove instrumental in job searches, business ventures, and overall professional growth.",
    },
    {
      imgSrc: "/assets/images/Blogs/Third-tab/img-3.png",
      title: "Accelerating Career Progression Through Specialized Skills",
      text:
        "Completing an online MBA significantly enhances your prospects for career growth. Graduates with an MBA are more likely to secure promotions and command higher incomes. The acquired skills empower individuals to tackle challenges strategically, design effective solutions, and contribute meaningfully to their organizations. With the global importance and continuous growth of the MBA market, being an MBA holder in your field positions you favorably for career advancement.",
    },
    {
      imgSrc: "/assets/images/Blogs/Third-tab/img-4.png",
      title: "Embracing Continuous Growth in a Dynamic Business Landscape",
      text:
        "Completing an online MBA significantly enhances your prospects for career growth. Graduates with an MBA are more likely to secure promotions and command higher incomes. The acquired skills empower individuals to tackle challenges strategically, design effective solutions, and contribute meaningfully to their organizations. With the global importance and continuous growth of the MBA market, being an MBA holder in your field positions you favorably for career advancement.",
    },
  ];

  return (
    <>
      <section className="blog-career-path-section">
        <div className="container">
          <h1 className="title heading">
            {" "}
            Elevating Your Career Path with an Online MBA Degree{" "}
          </h1>
          <div className="blog-swiper">
            <Swiper
              spaceBetween={50}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
                1600: {
                  slidesPerView: 3,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {slideContent?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="career-path-card">
                    <div className="img-div">
                      <img
                        className="swiper-img"
                        src={process.env.PUBLIC_URL + slide.imgSrc}
                        alt={`Slide ${index + 1}`}
                      />
                    </div>
                    <div className="overlay-color" />
                    <div className="overlay-text-before-hover">
                      <h3 className="title">{slide.title}</h3>
                    </div>
                    <div className="overlay-text-after-hover">
                      <p>{slide.text}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default CareerPath;
