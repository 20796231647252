import {
  postData,
} from "./api";


export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);

  return a;
};

export const Select2Data = async (data, name, other = false) => {
  const result = data?.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export const sendMobileOTP = async (contact_no) => {
  try {
    const res = await postData(`/seller/verify-otp/mobile-otp-send`, {
      contact_no,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const verifyMobileOTP = async (contact_no, otp) => {
  try {
    const res = await postData(`/seller/verify-otp/mobile-verify-otp`, {
      contact_no,
      otp,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};
