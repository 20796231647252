import React from 'react'
import { Form } from 'react-bootstrap'
import PartnerForm from './PartnerForm/PartnerForm'
import Partnerbanner from './Partnerbanner/Partnerbanner'
import BecomePartnerSlider from './BecomePartnerSlider/BecomePartnerSlider'
const BecomePartnerPage = () => {
    return (
        <>
            <Partnerbanner />
            <PartnerForm />
            <BecomePartnerSlider />

        </>
    )
}

export default BecomePartnerPage