import React from "react";
import "../SkillQuestions/AllQuestion.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

const Question7 = ({
  setValue,
  getValues,
  errors,
  setError,
  control,
}) => {
  // Define an array of objects representing tab content
  const tabsContent = [
    { id: "1", key: "1", title: "The Right University + Higher Salary Package" },
    { id: "3", key: "3", title: "Less Fees Structure" },
    { id: "4", key: "4", title: "I Only Care about Degree" },
  ];




  // Get the initial selected value or default to the first item
  const selectedValue = getValues("what_matters");


  //  console.log(getValues());
  return (
    <>
      <section className="Questions-sec">
        <div className="container p-0">
          <div className="StepForm-holder">
            <h3>What Matters You the Most?</h3>
          </div>
          <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey={selectedValue}>
              <div className="row stepsevenmain">
                <div className="col-lg-8 mx-auto">
                  <Nav variant="pills" className="justify-content-center">
                    {/* Map over the array of tab content */}
                    {tabsContent.map((tab) => (
                      <Nav.Item key={tab.id}>
                        <Nav.Link
                          eventKey={tab.id}
                          className={
                            getValues("what_matters") === tab.title ? "active" : ""}
                          onClick={() => {
                            setValue("what_matters", tab.title);
                            setError("what_matters", "");
                          }}
                        >
                          <div className="stepseven">
                            <p>{tab.title}</p>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
        <br />
        <div className="text-center">
          <sub className="text-danger ">{errors?.what_matters?.message}</sub>
        </div>
      </section>
    </>
  );
};

export default Question7;
