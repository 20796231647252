import React, { useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "./whoweare.css"
import LazyLoad from 'react-lazyload'
import parse from "html-react-parser";
import { ShimmerSectionHeader, ShimmerText, ShimmerThumbnail } from "react-shimmer-effects";
import { Col, Container, Row } from "react-bootstrap";

const Whoweare = () => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const videoRefs = useRef([]);
    const [showShimmer, setShowShimmer] = useState(true);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/home/who-we-are`);
            setData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getDataAll();
    }, []);

    useEffect(() => {
        const handleObserver = (entries) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    entry.target.pause();
                }
            });
        };

        const observer = new IntersectionObserver(handleObserver, {
            threshold: 0.5,
        });

        videoRefs.current.forEach(video => {
            if (video) {
                observer.observe(video);
            }
        });

        return () => {
            videoRefs.current.forEach(video => {
                if (video) {
                    observer.unobserve(video);
                }
            });
        };
    }, [data]);


    useEffect(() => {
        const delay = setTimeout(() => {
            setShowShimmer(false);
        }, 3000);

        getDataAll();

        return () => clearTimeout(delay);
    }, []);


    return (
        <>
            {showShimmer &&
                <Container>
                    <div className="mt-5">
                        <Row>
                            <Col lg={7}>
                                <ShimmerSectionHeader />
                                <ShimmerText line={5} gap={10} />;
                            </Col>
                            <Col lg={5}>
                                <ShimmerThumbnail height={250} rounded />;
                            </Col>
                        </Row>                        
                    </div>
                </Container>
            }
            {!showShimmer && (
                <section className='who-we-section'>
                    <div className='container'>
                        {data?.map((val, index) =>
                            <div className='row' key={index}>
                                <div className='col-lg-7'>
                                    <div className='sec-1'>
                                        <p className='who-title title'>{val?.quotion}</p>
                                        <h1 className='subtitile'>{val?.sub_title}</h1>
                                        <p className='content'>{parse(val?.description) || ""}</p>
                                    </div>
                                </div>
                                <div className='col-lg-5 my-auto'>
                                    <div className='sec-2'>
                                        <div className='video-div'>
                                            <LazyLoad>
                                                <video
                                                    className='video'
                                                    controls
                                                    autoPlay
                                                    loop
                                                    muted
                                                    ref={el => videoRefs.current[index] = el}
                                                >
                                                    <source src={IMG_URL + val?.video}></source>
                                                </video>
                                            </LazyLoad>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            )}
        </>

    );
}

export default Whoweare;
