import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";

import './SmallBanMca.css'
import "swiper/css/pagination";


function SmallBanMca({ id }) {

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState();

    const getDataAll = async () => {
        const response = await getData(
            `/without-login/ed-tech/courses/common/common-type/small-banner/${id}`

        );
        await setData(response?.data);
    };


    console.log(data, "svsahvsavsv");
    useEffect(() => {
        getDataAll(id);
    }, [id]);

    return (
        <>
            {data &&
                <section className='mca-small-banner-section'>
                    <div className='container'>
                        <div className='row'>
                            {data &&
                                <div className='desktopview-small-banner'>
                                    <img
                                        className='mca-ban-img'
                                        src={IMG_URL + data?.image}
                                    />
                                </div>
                            }
                            {data &&

                                <div className='mobileview-small-banner'>
                                    {/* <img className='mca-ban-img' src={process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Banner/MCA.png"} /> */}
                                    <img className='mca-ban-img' src={IMG_URL + data?.image} />
                                </div>
                            }
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default SmallBanMca