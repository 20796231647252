import React from 'react'
import './FAQs.css'
import { Accordion, Container } from 'react-bootstrap'

function FAQs() {
  return (
    <>
      <section className='faqs-section'>
        <Container>
          <div className='row'>
            <div className='heading'>
              <h4 className='title mb-1'>FAQs Related to Online MBA vs Regular MBA</h4>
              {/* <div className='text-border' /> */}
            </div>
            <div className='question'>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header >Are online MBAs and distance learning MBAs the same?</Accordion.Header>
                  <Accordion.Body>
                    <p>A Distance MBA is a management degree program designed for self-paced learning using study materials. Conversely, an Online MBA is also a management
                       degree program available in a virtual format, allowing students to pursue their studies entirely through online resources.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className='mt-4'>
                  <Accordion.Header >Does a distance MBA have value?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe. We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals. With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='mt-4'>
                  <Accordion.Header >Is distance learning MBA worth it?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe. We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals. With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className='mt-4'>
                  <Accordion.Header >Are there separate eligibility criteria or admission requirements for online or offline MBAs?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe. We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals. With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className='mt-4'>
                  <Accordion.Header >Which is better: Regular MBA Or Online MBA</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe.
                      We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals.
                      With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className='mt-4'>
                  <Accordion.Header >Online MBA VS Regular MBA</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe.
                      We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals.
                      With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6" className='mt-4'>
                  <Accordion.Header className='title'>Why Choose Online MBA</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe.
                      We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals.
                      With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7" className='mt-4'>
                  <Accordion.Header className='title'>What is the duration of a distance MBA?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe.
                      We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals.
                      With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default FAQs