import React, { useContext, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { postData } from "../../../../../utils/api";
import { Context } from "../../../../../utils/context";
import { useParams } from 'react-router-dom';
import ResetModal from "../../../../Job-Portal/JP-Common-Elements/Jp-modals/ResetModal/ResetModal";
import FirstStep from "./First_step/FirstStep";
import Question1 from "./Question1";
import Question2 from "./Question2";
import Question3 from "./Question3";
import Question4 from "./Question4";
import Question5 from "./Question5";
import Question6 from "./Question6";
import Question7 from "./Question7";
import Question8 from "./Question8";
import Question9 from "./Question9";
import Question10 from "./Question10";
import Question11 from "./Question11";
import Question12 from "./Question12";
import "./AllQuestion.css";

const SkillQuestions = () => {
  const { id } = useParams();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({});

  const { IMG_URL } = useContext(Context);
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const totalSteps = 12;

  const validateStep = () => {
    const requiredFields = {
      1: "course_type_id",
      2: "course_id",
      3: "specialization_id",
      4: "working",
      5: "hours",
      6: "budget",
      7: "emi",
      8: "what_matters",
      9: "sallary_package",
      10: "qualification_id",
      11: "percentage",
    };

    const field = requiredFields[currentStep];
    if (!getValues(field)) {
      setError(field, {
        type: "manual",
        message: `Please Select ${field.replace(/_/g, " ").toUpperCase()}`,
      });
      return false;
    }
    clearErrors(field);
    return true;
  };

  const handleNext = useCallback(() => {
    if (validateStep()) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  }, [currentStep]);

  const handleBack = useCallback(() => {
    setCurrentStep((prevStep) => prevStep - 1);
  }, []);

  const onSubmit = async (e) => {
    try {
      const finalData = new FormData;
      finalData.append("specialization_id", getValues("specialization_id")?.value);
      finalData.append("state_id", getValues("state_id")?.value);
      finalData.append("university_id", getValues("university_id")?.value);
      finalData.append("budget", getValues("budget"));
      finalData.append("course_id", getValues("course_id"));
      finalData.append("course_type_id", getValues("course_type_id"));
      finalData.append("email", getValues("email"));
      finalData.append("emi", getValues("emi"));
      finalData.append("hours", getValues("hours"));
      finalData.append("mobile", getValues("mobile"));
      finalData.append("name", getValues("name"));
      finalData.append("percentage", getValues("percentage"));
      finalData.append("qualification_id", getValues("qualification_id"));
      finalData.append("sallary_package", getValues("sallary_package"));
      finalData.append("what_matters", getValues("what_matters"));
      finalData.append("working", getValues("working"));
      if (id && !isNaN(Number(id))) {
        finalData.append("guide_id", id);
      }

      console.log("finalData", finalData?.data);
      const res = await postData("/without-login/ed-tech/registration-form", finalData);

      if (res?.success) {
        setModalShow(true); // Show modal on success
        reset(); // Reset the form
        setTimeout(() => {
          setModalShow(false);
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error("Error submitting form:", error); // Log any errors
    }
  };
  console.log("getValues", getValues());
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <FirstStep setValue={setValue} getValues={getValues} errors={errors} setError={setError} />;
      case 2:
        return <Question1 setValue={setValue} getValues={getValues} errors={errors} setError={setError} clearErrors={clearErrors} setSelectedCourse={setSelectedCourse} />;
      case 3:
        return <Question2 getValues={getValues} control={control} errors={errors} setError={setError} register={register} clearErrors={clearErrors} />;
      case 4:
        return <Question3 setValue={setValue} getValues={getValues} control={control} errors={errors} setError={setError} clearErrors={clearErrors} />;
      case 5:
        return <Question4 setValue={setValue} getValues={getValues} control={control} errors={errors} setError={setError} clearErrors={clearErrors} />;
      case 6:
        return <Question5 setValue={setValue} getValues={getValues} control={control} errors={errors} setError={setError} clearErrors={clearErrors} />;
      case 7:
        return <Question6 setValue={setValue} getValues={getValues} control={control} errors={errors} setError={setError} clearErrors={clearErrors} />;
      case 8:
        return <Question7 setValue={setValue} getValues={getValues} control={control} errors={errors} setError={setError} clearErrors={clearErrors} />;
      case 9:
        return <Question8 setValue={setValue} getValues={getValues} control={control} errors={errors} setError={setError} clearErrors={clearErrors} />;
      case 10:
        return <Question9 setValue={setValue} getValues={getValues} control={control} errors={errors} setError={setError} clearErrors={clearErrors} />;
      case 11:
        return <Question10 setValue={setValue} getValues={getValues} control={control} errors={errors} setError={setError} clearErrors={clearErrors} />;
      case 12:
        return <Question12
          control={control}
          register={register}
          errors={errors}
          setError={setError}
          getValues={getValues}
          selectedCourse={selectedCourse}
        />;
      default:
        return null;
    }
  };

  const renderNavigationButtons = () => (
    <div className="navigation-buttons">
      {currentStep > 1 && (
        <div className="apply-btn-holder">
          <Button className="back-btn" onClick={handleBack}>
            Back
          </Button>
        </div>
      )}
      <div className="apply-btn-holder">
        {currentStep === totalSteps ? (
          <Button className="apply_now_btn"
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Done
          </Button>
        ) : (
          <Button className="apply_now_btn" onClick={handleNext} type="button">
            Next
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div className="QuestionsStepFormalumini">
      <div className="StepForm">
        <Form
        // onSubmit={handleSubmit(onSubmit)}
        >
          <div className="shadow-container">
            <div className="progress-indicator">
              <div
                className="progress-style"
                style={{
                  width: `${(currentStep / totalSteps) * 100}%`,
                  height: "7px",
                  background: "#E88501",
                  transition: "width 0.3s ease",
                  borderRadius: "8px",
                }}
              />
            </div>
            {renderStep()}
            {renderNavigationButtons()}
          </div>
        </Form>
      </div>
      {modalShow && (
        <ResetModal show={modalShow} onHide={() => setModalShow(false)} text={"Your Application has been Submitted Successfully!"} />
      )}
    </div>
  );
};

export default SkillQuestions;
