import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "react-bootstrap/Container";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./BhartiAdvantage.css";
import parse from "html-react-parser";
import { ShimmerPostItem, ShimmerSectionHeader, ShimmerSocialPost, ShimmerThumbnail } from "react-shimmer-effects";
import { Col, Row } from "react-bootstrap";

const BhartiAdvantage = ({ universityName, id }) => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getDataAll = async () => {
      try {
        const response = await getData(
          `/without-login/ed-tech/university/common/advantages/${id}`
        );
        setData(response?.data);
        console.log(response?.data, "advantage");
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const delay = setTimeout(() => {
      getDataAll();
    }, 3000);

    return () => clearTimeout(delay);
  }, [id, getData]);

  const AdvantageDetails = [
    // Your static advantage details
  ];

  return (
    <>
      {isLoading &&
        <Container>
          <ShimmerSectionHeader center />
          <Row>
            <Col md={3} sm={6} xs={12} >
              <ShimmerPostItem
                card
                title
                cta
                imageType="thumbnail"
                imageWidth={80}
                imageHeight={80}
                contentCenter
              />
            </Col>
            <Col md={3} sm={6} xs={12} >
              <ShimmerPostItem
                card
                title
                cta
                imageType="thumbnail"
                imageWidth={80}
                imageHeight={80}
                contentCenter
              />
            </Col>
            <Col md={3} sm={6} xs={12} >
              <ShimmerPostItem
                card
                title
                cta
                imageType="thumbnail"
                imageWidth={80}
                imageHeight={80}
                contentCenter
              />
            </Col>
            <Col md={3} sm={6} xs={12} >
              <ShimmerPostItem
                card
                title
                cta
                imageType="thumbnail"
                imageWidth={80}
                imageHeight={80}
                contentCenter
              />
            </Col>
          </Row>
        </Container>
      }
      {!isLoading && data && data.length > 0 && (
        <section className="bharti-advantages-section">
          <Container>
            <div className="heading text-center">
              <h1 className="title">Advantages</h1>
              <div className="text-border"></div>
            </div>

            <div className="bharti-adv-swiper">
              <Swiper
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1440: {
                    slidesPerView: 4,
                  },
                }}
                className="mySwiper px-2"
              >
                {data.map((val, index) => (
                  <SwiperSlide key={index}>
                    <div className="cards-div">
                      <div className="main-card-adv">
                        <h5 className="title">{val.name}</h5>
                        <p>{parse(val.description)}</p>
                      </div>
                      <div className="img-div">
                        <div className="circle">
                          <img
                            className="main-img"
                            src={IMG_URL + val.image}
                            alt={val.name}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Container>
        </section>
      )}
    </>
  );
};

export default BhartiAdvantage;
