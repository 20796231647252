import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "./wedeliver.css";
import parse from "html-react-parser";

const Wedeliver = ({ id }) => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/common/future/${id}`
      );
      setData(response?.data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();

  }, [id]);

  return (
    <>
      {data && data?.length > 0 &&
        <section className="we-deliver-section">
          <div className="container">
            {/* {data?.map((val) => ( */}
            <div className="contttt-div">
              <h3 className="title wede-title">{data?.name}</h3>
              <p className="wede-cont">{htmlToReactParser.parse(data?.description)}</p>
            </div>
            {/* ))} */}
          </div>
        </section>
      }
    </>
  );
};

export default Wedeliver;
