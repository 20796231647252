import { React, useRef, useEffect, useState, useContext } from "react";
import "./Onlinemca.css";
import ImageContext from "../../../../../Utils/MyContext/MyContext";
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import BecomePartner from "../../../../become-partner/BecomePartner";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { ShimmerSectionHeader, ShimmerThumbnail } from "react-shimmer-effects";

function Onlinemca({ id }) {
  const { online } = useContext(ImageContext);
  const { getData, IMG_URL } = useContext(Context);

  // become partner modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/common/common-type/what/${id}`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      getDataAll(id);
    }, 3000);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [id]);

  const handleScroll = () => {
    document.querySelectorAll('.online-video').forEach(video => {
      video.pause();
      video.currentTime = 0;
    });
  };

  return (
    <>
      {loading && 
      <Container className="mt-5">
        <Row>
          <Col lg={7} md={7} sm={12} xs={12} className="mt-5">
              <ShimmerSectionHeader />
          </Col>
            <Col lg={5} md={5} sm={12} xs={12}>
              <ShimmerThumbnail height={250} rounded />
            </Col>
        </Row>
      </Container>
      }
      {!loading && data && data.length > 0 &&
        <section className="OnlineMca-section">
          <div className="main-onlin-sec">
            <div className="online-content">
              <div className="container">
                {data.map((val, index) => (
                  <div className="row" key={index}>
                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12">
                      <div className="descrptn">
                        <h5 className="title">{val?.name}</h5>
                        <p>{parse(val?.description) || ""}</p>
                      </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12">
                      <div className="video-div">
                        <video
                          className="online-video"
                          playsInline
                          autoPlay
                          muted
                          controls
                        >
                          <source src={IMG_URL + val.video}></source>
                        </video>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
            <Modal.Header closeButton>
              <Modal.Title>Become A Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BecomePartner />
            </Modal.Body>
          </Modal>
        </section>
      }
    </>
  );
}

export default Onlinemca;
