import React from 'react'
import './UpgradeCareer.css'
function UpgradeCareer() {
    return (
        <section className='upgrade-section'>
            <div className='container'>
                <div className='upgrade-main '>
                    <div className='row '>
                        <div className=' col-lg-8 col-md-8 col-sm-12 col-12 my-auto'>
                            <div className='upgrade-contents'>
                                <h2 className='title'>Which Is the Best Choice to Upgrade Your Career?</h2>
                                <p>The decision between a Regular MBA and an Online MBA ultimately depends on your individual preferences,
                                    lifestyle, and career goals. However, for those looking to upgrade their careers without compromising on
                                    flexibility and accessibility, promoting Online MBA programs becomes paramount.</p>
                                <p>Online MBAs cater to a diverse audience, including working professionals, distance learners, and individuals with varying schedules.
                                    The elimination of geographical constraints and the ability to continue working while pursuing an advanced degree make Online MBAs an inclusive
                                    and practical choice.</p>
                                <p>Moreover, the emphasis on written communication, self-discipline, and cost considerations align with the demands
                                    of the contemporary business landscape. As the business world increasingly values remote collaboration
                                    and digital communication, the skills cultivated through an Online MBA become increasingly relevant and desirable.</p>
                            </div>
                        </div>
                        <div className=' col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='upgrade-image-div'>
                                <img className='ugrade-image' src={process.env.PUBLIC_URL + "/assets/images/Blogs/innerpages/upgrade.png"} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    )
}

export default UpgradeCareer