import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import './Partnerbanner.css';

const Partnerbanner = () => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDataAll = async () => {
        try {
            const response = await getData(
                `/without-login/ed-tech/customer-management/become-partner-banner`
            );
            setData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 3000); 
        }
    };

    useEffect(() => {
        getDataAll();
    }, []);

    return (
        <section className='partnerbanner-sec'>
            <div className='container'>
                {loading ? (
                    <ShimmerThumbnail height={350} rounded />
                ) : (
                    <Swiper
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {data?.map((image, index) => (
                            <SwiperSlide key={image.id}>
                                <div className="image-div">
                                    <img
                                        src={IMG_URL + image?.image}
                                        className="bca-img"
                                        alt={`Partner Image ${index}`}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </div>

            {/* Mobile view */}
            <div className="image-div mobile-view-banner">
                {loading ? (
                    <ShimmerThumbnail height={350} rounded />
                ) : (
                    <Swiper
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {data?.map((image, index) => (
                            <SwiperSlide key={image.id}>
                                <div className="image-div">
                                    <img
                                        src={IMG_URL + image?.image}
                                        className="bca-img"
                                        alt={`Partner Image ${index}`}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </div>
        </section>
    );
}

export default Partnerbanner;
