import React, { useEffect, useState } from "react";
import "./AddvertisementSec.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Container } from "react-bootstrap";

const AddvertisementSec = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const Addvertisementslider = [
        { image: "/assets/images/university/bharati-vidyapeeth/banner/Banner_1.png" },
        { image: "/assets/images/university/bharati-vidyapeeth/banner/Banner_2.png" },
        { image: "/assets/images/university/bharati-vidyapeeth/banner/Banner_1.png" },
        { image: "/assets/images/university/bharati-vidyapeeth/banner/Banner_2.png" },
        { image: "/assets/images/university/bharati-vidyapeeth/banner/Banner_1.png" },
        { image: "/assets/images/university/bharati-vidyapeeth/banner/Banner_2.png" },
    ];

    return (
        <>
            {loading ? (
                <Container>
                    <ShimmerThumbnail height={350} rounded />
                </Container>
            ) : (
                <section className='addvertisement-section'>
                    <div className='container'>
                        <div className="title-profcyma-help text-center py-5">
                            <h5>Advertisement</h5>
                            <h2 className="title">Our Popular Advertisement</h2>
                        </div>
                        <div className='slider-section'>
                            <Swiper
                                pagination={{ clickable: true }}
                                modules={[Pagination]}
                                className="mySwiper"
                                breakpoints={{
                                    0: { slidesPerView: 1, spaceBetween: 20 },
                                    420: { slidesPerView: 1, spaceBetween: 20 },
                                    486: { slidesPerView: 2, spaceBetween: 20 },
                                    768: { slidesPerView: 2, spaceBetween: 20 },
                                    992: { slidesPerView: 3, spaceBetween: 20 },
                                    1200: { slidesPerView: 4, spaceBetween: 20 },
                                    1400: { slidesPerView: 4, spaceBetween: 20 },
                                }}
                            >
                                {Addvertisementslider.map((sliderimage, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='banner-slide-div mb-5'>
                                            <div className='image-div'>
                                                <img className='banner-img' src={process.env.PUBLIC_URL + sliderimage?.image} alt={`Banner ${index + 1}`} />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default AddvertisementSec;
