import React, { useContext, useEffect, useState } from "react";
import "../SkillQuestions/AllQuestion.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import { CourseSpecializations } from "../../../../../utils/apis/common/Common";
import { Select2Data } from "../../../../../utils/common";
import { Controller, useFieldArray, useForm } from "react-hook-form";
const Question2 = ({ control, errors, setError, clearErrors, getValues }) => {
  const { IMG_URL } = useContext(Context);
  const [courseSpecializations, setCourseSpecializations] = useState();

  const getCourSespecializations = async () => {
    const res = await CourseSpecializations(getValues("course_id"));
    if (res?.success) {
      // setCourseSpecializations(res.data);
      setCourseSpecializations(
        await Select2Data(res?.data, "specialization_id")
      );
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getCourSespecializations();
  }, []);

  // console.log(getValues());
  return (
    <>
      <section className="Questions-sec">
        <div className="container p-0">
          <div className="StepForm-holder">
            <h3>Have a Particular Specialization in Mind?</h3>
          </div>

          <div className="row justify-content-center">
            <div className="aluminiselect">


              <Controller
                control={control}
                name="specialization_id"
                defaultValue="" // Set default value if needed
                render={({ field }) => (
                  <Select
                    {...field}
                    options={courseSpecializations}
                    className={classNames("", {
                      "is-invalid": errors?.specialization_id,
                    })}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                      if (selectedOption) {
                        clearErrors("specialization_id");
                      }
                    }}
                  />
                )}
                rules={{ required: "Specialization is required" }}
              />

            </div>
          </div>
          <br />
          <div className="text-center">
            <sub className="text-danger ">
              {errors?.specialization_id?.message}
            </sub>
          </div>
        </div>
      </section>
    </>
  );
};

export default Question2;