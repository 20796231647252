import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "../about-banner/AboutBanner.css";
import ImageContext from "../../../Utils/MyContext/MyContext";

const AboutBanner = () => {
  const { name } = useContext(ImageContext);

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/about-us/banner`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="about-banner mt-2">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 desktop-view-banner">
              {/* <div className='banner-img-main'>
                                {name.map((image) => (
                                    <img key={image.id} src={image.image7} className='banner-img' />
                                ))}
                                {/* <img src={process.env.PUBLIC_URL + "/assets/images/about/About-US-a.png"} className="banner-img" alt="..." /> */}
              {/* </div> */}
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide>
                    <div className="banner-img-main">
                      <img
                        key={image.id}
                        src={IMG_URL + image?.image}
                        className="banner-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-md-12 mobile-view-banner">
              {/* <div className='banner-img-main'>
                                {name.map((image) => (
                                    <img key={image.id} src={image.image29} className='banner-img1' />
                                ))}
                            </div> */}
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide>
                    <div className="banner-img-main">
                      <img
                        key={image.id}
                        src={IMG_URL + image?.image}
                        className="banner-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutBanner;
