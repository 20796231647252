import React, { useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "./Faq.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import parse from "html-react-parser";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Faq = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (data && data.length > 0) {
      setActiveKey(data[0]?.id);
    }
  }, [data]);

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/home/faq`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  const handleAccordionToggle = (faq_id) => {
    setActiveKey(activeKey === faq_id ? null : faq_id);
  };

  return (
    <>
      {isLoading ? (
        <ShimmerThumbnail height={600} rounded />
      ) : (
        <section className="homefaq">
          <div className="container">
            <div className="row">
              <div className="heading-holder text-center mb-4">
                <h4 className="title">Frequently asked questions</h4>
                <p>One call, endless answers: ignite success.</p>
              </div>
              <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                <Nav variant="pills" className="justify-content-center">
                  {data?.map((val) => (
                    <Nav.Item key={val?.id}>
                      <Nav.Link eventKey={val?.id} className="custom-nav-link">{val?.name}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <div className=" bg-color mt-5">
                  <Tab.Content>
                    {data?.map((val) => (
                      <Tab.Pane key={val.id} eventKey={val?.id}>
                        <Accordion defaultActiveKey="0">
                          {val?.ed_tech_faq_subtypes?.map((faq, index) => (
                            <Accordion.Item key={faq.faq_id} eventKey={index.toString()}>
                              <Accordion.Header>{faq.name}</Accordion.Header>
                              <Accordion.Body>
                                <p>{parse(faq.description)}</p>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Faq;
