import React, { useState } from "react";
import "../SkillQuestions/AllQuestion.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

const Question10 = ({ setValue, getValues, errors, setError }) => {
  // Data for Nav links
  const navLinksData = [
    { id: "1", key: "1", label: "Below 50%", value: "Below 50%" },
    { id: "2", key: "2", label: "50 % to 80%", value: "50 % to 80%" },
    { id: "3", key: "3", label: "Above 80%", value: "Above 80%" },
  ];



  // Get the initial selected value or default to the first item
  const selectedValue = getValues("percentage");

  // console.log(getValues());
  return (
    <>
      <section className="Questions-sec">
        <div className="container p-0">
          <div className="StepForm-holder">
            <h3>Percentage Scored in Last Qualification?</h3>
          </div>
          <div>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={selectedValue}
            >
              <div className="row">
                <div className="col-lg-6 mx-auto">
                  <Nav variant="pills" className="justify-content-center">
                    {/* Mapping over the navLinksData array */}
                    {navLinksData?.map((item) => (
                      <Nav.Item
                        key={item.id}
                        className={
                          getValues("percentage") === item.value ? "active" : ""
                        }
                        onClick={() => {
                          setValue("percentage", item.value);
                          setError("percentage", "");
                        }}
                      >
                        <Nav.Link eventKey={item.value}>{item.value}</Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>{" "}
        <br />
        <div className="text-center">
          <sub className="text-danger ">{errors?.percentage?.message}</sub>
        </div>
      </section>
    </>
  );
};

export default Question10;
