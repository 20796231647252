import React from 'react'

import Modal from 'react-bootstrap/Modal';
import "./ResetModal.css"
import Success from '../../Animation/Success';
const ResetModal = (props) => {
    return (
        <>
            <section>
                <Modal
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='Resetmodal'
                >

                    <Modal.Body>
                        <div>
                            <div>
                                <Success />

                            </div>
                            <div className='resetpasstxt'>
                                <p>{props.text}</p>
                                <p>Have a Great Time!</p>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
            </section>
        </>
    )
}

export default ResetModal