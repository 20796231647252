import React, { useState } from "react";
import "../SkillQuestions/AllQuestion.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";

const Question4 = ({
  setValue,
  getValues,
  control,
  errors,
  setError,
}) => {
  // Define an array containing the data for each Nav.Link
  const navItems = [
    { id: "1", key: "1", label: "2 - 4 Hrs." },
    { id: "2", key: "2", label: "4 - 8 Hrs." },
    { id: "3", key: "3", label: "8 - 16 Hrs." },
    { id: "4", key: "4", label: "16 Hrs." },
  ];



  // Get the initial selected value or default to the first item
  const selectedValue = getValues("hours");

  //  console.log(getValues());
  return (
    <>
      <section className="Questions-sec">
        <div className="container p-0">
          <div className="StepForm-holder">
            <h3>How many Status Hours You can give on the Weekly Basis?</h3>
          </div>
          <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey={selectedValue}>
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <Nav variant="pills" className="justify-content-center">

                    {navItems?.map((item) => (
                      <Nav.Item
                        key={item.id}
                        className={
                          getValues("hours") === item.label ? "active" : ""
                        }
                        onClick={() => {
                          setValue("hours", item?.label);
                          setError("hours", "");
                        }}
                      >
                        <Nav.Link eventKey={item.label}>{item.label}</Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
        <br />
        <div className="text-center">
          <sub className="text-danger ">{errors?.hours?.message}</sub>
        </div>
      </section>
    </>
  );
};

export default Question4;
