import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../utils/context";
import './AdmissionProcedure.css';
import { Container, Row } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ShimmerThumbnail } from 'react-shimmer-effects';

const AdmissionProcedure = ({ id }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const getDataAll = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/specialization/footer-image/${id}`);
        setData(response?.data[0]);
        setLoading(false); // Stop loading once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const timer = setTimeout(() => {
      getDataAll();
    }, 3000); // 3 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [getData, id]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {loading && <ShimmerThumbnail height={450} rounded />}
      {!loading && data && (
        <section className='admission-procedure'>
          <Container>
            <Row>
              <div className='procedure-image'>
                <img className="add-img" src={IMG_URL + data?.image} alt="" />
              </div>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default AdmissionProcedure;
