import React, { useContext, useEffect } from "react";
import "./ContactBanner.css";
import ImageContext from "../../../Utils/MyContext/MyContext";
import AOS from 'aos';
import 'aos/dist/aos.css';
const ContactBanner = () => {
  const { name } = useContext(ImageContext);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <section className="BBA_banner contact-banner">
        <div className="container">
          <div className="image-div desktop-view-banner">
            {name?.map((image) => (
              <img
                key={image.id}
                src={image.image27}
                className="bbabanner-img"
              />
            ))}
          </div>
          <div className="image-div mobile-view-banner">
            {name?.map((image) => (
              <img key={image.id} src={image.image36} className="banner-img1" />
            ))}
          </div>
        </div>
      </section>
      {/* card-section-start */}
      <section className="card-section" data-aos="fade-up" data-aos-duration="3000">
        <div className="container ">
          <div className="row ">
            <div className="col-md-4 ">
              <div className="main-card  ">
                <div className="card-content">
                  <h6>Address </h6>
                  <p>
                    2nd floor, Office no 3, Opp high class soc, M.no 734 Near
                    Suryadutta College, Bavdhan Khurd, Pune 411021.
                  </p>
                </div>
                <div className="address-icon">
                  <img
                    className="add-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/contact-us/location-pin.png"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="main-card  ">
                <div className="card-content">
                  <h6>Phone</h6>
                  <p>
                    +91 94057 09042 / +91 90284 16680 <br /> +91 96077 55557
                  </p>
                </div>
                <div className="address-icon">
                  <img
                    className="call-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/contact-us/call.png"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="main-card  ">
                <div className="card-content">
                  <h6>Email</h6>
                  <p>
                    info@profcyma.com <br /> support@profcyma.com
                  </p>
                </div>
                <div className="address-icon ">
                  <img
                    className="email-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/contact-us/email.png"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* card-section-end */}
    </>
  );
};

export default ContactBanner;
