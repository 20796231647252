import { React, useRef, useEffect, useState, useContext } from "react";
import './QualifiedMca.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
function QualifiedMca({ id, courseName }) {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    const slidesData = [
        {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/academic-qualitfication.png",
            title: "Academic Qualifications",
            description: "Elevate your career with a solid foundation – a bachelor’s degree in a relevant field is a prerequisite for MCA online programs."
        },
        {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/Professional-Experience.png",
            title: "Professional Experience",
            description: "Climb the tech ladder with real-world insights. Most online MCA programs prefer applicants with professional exper-ience, ensuring a dynamic and practical learning environment."
        },
        {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/Technological-Proficiency.png",
            title: "Technological Proficiency",
            description: "Navigate the digital landscape effortlessly. Adeptness with various tech tools ensures a smooth online learning experience, aligning perfectly with the demands of the tech-driven MCA field. Embrace the future with an online MCA tailored for working professionals."
        },
        {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/time-management.png",
            title: "Strong Time Management Skills",
            description: "Tailored for the working professional, online MCAs demand impeccable time management, allowing you to balance work commitments while excelling in your studies."
        },
        {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/negotiation.png",
            title: "Effective Communication Skills",
            description: "Articulate your ideas seamlessly. Online MCA programs foster collaboration; hence, effective communication skills are paramount for virtual discussions and group projects."
        },

    ];

    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/courses/common/common-type/who-qualified/${id}`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(courseName, "courseNamecourseNamecourseName");
    useEffect(() => {
        getDataAll(id);
    }, [id]);
    return (
        <>
            {data && data?.length > 0 &&
                <section className='mca-qualified-section'>
                    <div className='container'>
                        <div className='col-xxl-6 col-xl-6 col-lg-8  col-md-12 col-12 mx-auto'>
                            <div className='heading'>
                                <h4 className='title'>Who Qualified For Online {courseName}?</h4>
                                <p>Unlocking a world of opportunities, pursuing an online {courseName} demands a unique set of qualifications, tailored for working professionals.</p>
                            </div>
                        </div>
                        <Swiper

                            spaceBetween={20}
                            pagination={{
                                clickable: true,
                            }}
                            initialSlide={1}
                            centeredSlides={true}

                            breakpoints={{
                                0: { slidesPerView: 1 },
                                768: { slidesPerView: 1.5 },
                                992: { slidesPerView: 2 },
                                1200: { slidesPerView: 3 },

                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                        >
                            {data?.map((slide, index) => (
                                <SwiperSlide key={index}>
                                    <div className='mca-qualified-card'>
                                        <div className='row px-3 mt-3'>
                                            <div className='col-xxl-2 col-xl-2 col-lg-2 col-2'>
                                                <div className='icons-main-div'>
                                                    <img className='main-icon' src={IMG_URL + slide.image} alt="icon" />
                                                </div>
                                            </div>
                                            <div className='col-xxl-10 col-xl-10 col-lg-10 col-10'>
                                                <div className='title-sec'>
                                                    <h5>{slide?.name}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='descriptn px-3'>
                                            <p>{parse(slide?.description) || ""}</p>
                                        </div>
                                        <div className='curve-div'>
                                            <img className='curve-img' src={process.env.PUBLIC_URL + "/assets/images/Courses/msc/curve.png"} />
                                        </div>
                                    </div>
                                </SwiperSlide>

                            ))}

                        </Swiper>
                        <div className='navigations'>
                            <div
                                className="main-slider "
                                onClick={() => handleMainSliderClick("prev")}
                            >
                                <div className='main-arrow-back'>
                                    <div className="arrow-back arrowmobile" />
                                </div>
                            </div>
                            <div
                                className="main-slider"
                                onClick={() => handleMainSliderClick("next")}
                            >
                                <div className='main-arrow-next'>
                                    <div className="arrow-next arrowmobile" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default QualifiedMca