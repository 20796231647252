import React, { useContext, useEffect, useState } from "react";
import "../SkillQuestions/AllQuestion.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import { allState, getAllUniversity } from "../../../../../utils/apis/common/Common";
import classNames from "classnames";

const Question12 = ({ control, register, errors, selectedCourse, getValues }) => {
  const { Select2Data } = useContext(Context);
  const [states, setStates] = useState([]);
  const [university, setUniversity] = useState([]);

  const GetAllStates = async () => {
    const res = await allState();
    if (res?.success) {
      setStates(await Select2Data(res?.data, "state_id"));
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  const GetAllUniversityData = async () => {
    const res = await getAllUniversity();
    if (res?.success) {
      setUniversity(await Select2Data(res?.data?.data, "university_id"));
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  useEffect(() => {
    GetAllStates();
    GetAllUniversityData();
  }, []);

  return (
    <section className="Questions-sec">
      <div className="container p-0">
        <div className="StepForm-holder">
          <h3>Let Us Help You for Your Better Future</h3>
        </div>
        <div>
          <Form>
            <div className="row steptwelve">
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    className={classNames("", {
                      "is-invalid": errors?.name,
                    })}
                    type="text"
                    {...register("name", {
                      required: "Name is required",
                      pattern: {
                        value: /^[A-Za-z ]*$/,
                        message: "Name must contain only letters",
                      },
                    })}
                    onKeyDown={(event) => {
                      if (!/[A-Z-a-z ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter Full Name"
                  />
                  {errors?.name && (
                    <span className="text text-danger">
                      {errors.name.message}
                    </span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="mobile">
                  <Form.Label>Contact No</Form.Label>
                  <Form.Control
                    className={classNames("", { "is-invalid": errors?.mobile })}
                    type="tel"
                    {...register("mobile", {
                      required: "Mobile number is required",
                      pattern: {
                        value: /^\d{10}$/,
                        message: "Mobile number must be a 10-digit number",
                      },
                    })}
                    onKeyDown={(event) => {
                      const key = event.key;
                      if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Mobile Number"
                    maxLength={10}
                  />
                  {errors?.mobile && (
                    <span className="text text-danger">
                      {errors.mobile.message}
                    </span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="state_id">
                  <Form.Label>State</Form.Label>
                  <Controller
                    control={control}
                    name="state_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={states}
                        className={errors?.state_id ? "is-invalid" : ""}
                      />
                    )}
                    rules={{ required: "State is required" }}
                  />
                  {errors?.state_id && (
                    <span className="text text-danger">
                      {errors.state_id.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    className={classNames("", { "is-invalid": errors?.email })}
                    type="email"
                    {...register("email", {
                      required: "E-mail is required",
                      pattern: {
                        value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                        message: "Invalid E-Mail Address",
                      },
                    })}
                    placeholder="E-Mail"
                  />
                  {errors?.email && (
                    <span className="text text-danger">
                      {errors.email.message}
                    </span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="university_id">
                  <Form.Label>University</Form.Label>
                  <Controller
                    control={control}
                    name="university_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={errors?.university_id ? "is-invalid" : ""}
                        options={university}
                      />
                    )}
                    rules={{ required: "University is required" }}
                  />
                  {errors?.university_id && (
                    <span className="text text-danger">
                      {errors.university_id.message}
                    </span>
                  )}
                </Form.Group>

                <div className="selected-course">
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>Selected Course:</Form.Label>
                    {selectedCourse ? (
                      <p>{selectedCourse.name}</p>
                    ) : (
                      <p>No course selected</p>
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default Question12;
