import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getBlog = async () => {
  try {
    return await getData(`/job-portal/blog/career-opportunities`);
  } catch (error) {
    console.log(error);
  }
};

export const savedUserJob = async () => {
  try {
    return await getData(`/job-portal/job-seeker/savejobs/getsave`);
  } catch (error) {
    console.log(error);
  }
};

export const forReject = async (data) => {
  try {
    return await postData(`/job-portal/job-seeker/interview/schedule`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getShortlisted = async () => {
  try {
    return await getData(`/job-portal/job-seeker/shortlist_jobs/shortlist`);
  } catch (error) {
    console.log(error);
  }
};

export const schedule = async (data) => {
  try {
    return await postData(`/job-portal/job-seeker/interview/schedule`, data);
  } catch (error) {
    console.log(error);
  }
};

export const interviewResult = async () => {
  try {
    return await getData(`/job-portal/job-seeker/interview/interview`);
  } catch (error) {
    console.log(error);
  }
};

export const getApplyedJob = async () => {
  try {
    return await getData(`/job-portal/job-seeker/applied-job/getuser`);
  } catch (error) {
    console.log(error);
  }
};

export const saveJobPost = async (data) => {
  try {
    return await postData(`/job-portal/job-seeker/savejobs/savejob`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getBlogSingle = async (id) => {
  try {
    return await getData(`/job-portal/blog/career-opportunities/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const allState = async () => {
  try {
    return await getData(`/without-login/master/all-state`);
  } catch (error) {
    console.log(error);
  }
};

export const allStateID = async (id) => {
  try {
    return await getData(`/without-login/master/all-state${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const selectedCity = async (id) => {
  try {
    return await getData(`/without-login/master/all-city/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const allCourse = async () => {
  try {
    return await getData(`/without-login/master/all-course`);
  } catch (error) {
    console.log(error);
  }
};



export const allCourseType = async () => {
  try {
    return await getData(`/without-login/master/all-course-types`);
  } catch (error) {
    console.log(error);
  }
};

export const allCourseByType = async (id) => {
  try {
    return await getData(`/without-login/master/all-courses/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Specializations = async () => {
  try {
    return await getData(`/without-login/master/all-specializations`);
  } catch (error) {
    console.log(error);
  }
};

export const SpecializationUniversity = async (id) => {
  try {
    return await getData(`/without-login/master/all-specialization-universities/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const CourseSpecializations = async (id) => {
  try {
    return await getData(`/without-login/master/all-course-specialization/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const privacyPolicy = async () => {
  try {
    return await getData(`/job-portal/privacy-policy`);
  } catch (error) {
    console.log(error);
  }
};

export const postRequestCallBack = async (data) => {
  try {
    return await postData(
      `/job-portal/customer-management/request-call-back`,
      data
    );
  } catch (error) {
    console.log(error);
  }
};

export const postContactUs = async (data) => {
  try {
    return await postData(`/job-portal/customer-management/contact-us`, data);
  } catch (error) {
    console.log(error);
  }
};

export const applyPost = async (data) => {
  try {
    return await postData(`/job-portal/job-seeker/jobs/applyjob`, data);
  } catch (error) {
    console.log(error);
  }
};

export const allLaguage = async () => {
  try {
    return await getData(`/without-login/master/all-languages`);
  } catch (error) {
    console.log(error);
  }
};

export const employeeResume = async () => {
  try {
    return await getData(`/job-portal/job-seeker/jobs/getuser`);
  } catch (error) {
    console.log(error);
  }
};

export const careerlevel = async () => {
  try {
    return await getData(`/without-login/master/all-career-level`);
  } catch (error) {
    console.log(error);
  }
};

export const jobType = async () => {
  try {
    return await getData(`/without-login/master/all-job-type`);
  } catch (error) {
    console.log(error);
  }
};

export const allPersonalStatus = async () => {
  try {
    return await getData(`/without-login/master/all-personal-status`);
  } catch (error) {
    console.log(error);
  }
};

export const allPersonalInfo = async () => {
  try {
    return await getData(`/without-login/master/all-personal-more-information`);
  } catch (error) {
    console.log(error);
  }
};

export const allProficiency = async () => {
  try {
    return await getData(`/without-login/master/all-proficiency`);
  } catch (error) {
    console.log(error);
  }
};

export const allSkills = async () => {
  try {
    return await getData(`/without-login/master/all-skill-type`);
  } catch (error) {
    console.log(error);
  }
};

export const allDepartment = async () => {
  try {
    return await getData(`/without-login/master/all-department`);
  } catch (error) {
    console.log(error);
  }
};

export const allJobType = async () => {
  try {
    return await getData(`/without-login/master/all-job-type`);
  } catch (error) {
    console.log(error);
  }
};

export const allJobStatus = async () => {
  try {
    return await getData(`/without-login/master/all-apply-status`);
  } catch (error) {
    console.log(error);
  }
};

export const allJobTitle = async () => {
  try {
    return await getData(`/without-login/master/all-job-title`);
  } catch (error) {
    console.log(error);
  }
};

export const allCity = async () => {
  try {
    return await getData(`/without-login/master/all-cities`);
  } catch (error) {
    console.log(error);
  }
};

export const allModeWork = async () => {
  try {
    return await getData(`/without-login/master/all-mode-of-work`);
  } catch (error) {
    console.log(error);
  }
};

export const allShifts = async () => {
  try {
    return await getData(`/without-login/master/all-shifts`);
  } catch (error) {
    console.log(error);
  }
};

export const allIndustries = async () => {
  try {
    return await getData(`/without-login/master/all-industry`);
  } catch (error) {
    console.log(error);
  }
};

export const allShift = async () => {
  try {
    return await getData(`/without-login/master/all-shifts`);
  } catch (error) {
    console.log(error);
  }
};

export const allEducation = async () => {
  try {
    return await getData(`/without-login/master/all-education`);
  } catch (error) {
    console.log(error);
  }
};

export const allInterviewType = async () => {
  try {
    return await getData(`/without-login/master/all-interview-type`);
  } catch (error) {
    console.log(error);
  }
};

export const personalInfo = async (data) => {
  try {
    return await postData(`/without-login/ed-tech/registration-form`, data);
  } catch (error) {
    console.log(error);
  }
};


export const ourAlumni = async () => {
  try {
    return await getData(`/without-login/ed-tech/home/our-alumni`);
  } catch (error) {
    console.log(error);
  }
};


export const ourAlumniPerson = async () => {
  try {
    return await getData(`/without-login/ed-tech/home/our-alumni-person`);
  } catch (error) {
    console.log(error);
  }
};


export const footer_university = async () => {
  try {
    return await getData(`/without-login/ed-tech/home/footer-university`);
  } catch (error) {
    console.log(error);
  }
};


export const getAllUniversity = async () => {
  try {
    return await getData(`/without-login/ed-tech/university/common/all-university `);
  } catch (error) {
    console.log(error);
  }
};

export const ourpopnews = async () => {
  try {
    return await getData(`/without-login/ed-tech/home/popular-news`);
  } catch (error) {
    console.log(error);
  }
};


export const popularcource = async () => {
  try {
    return await getData(`/without-login/ed-tech/blog/popular-course`);
  } catch (error) {
    console.log(error);
  }
};



export const latestpost = async () => {
  try {
    return await getData(`/without-login/ed-tech/blog/blogs`);
  } catch (error) {
    console.log(error);
  }
};


export const allGuidance = async () => {
  try {
    return await getData(`/without-login/ed-tech/home/right_guidence`);
  } catch (error) {
    console.log(error);
  }
};

export const suggestedCourses = async (id) => {
  try {
    return await getData(`/without-login/ed-tech/courses/suggested-course/data?cu_id=${id}`);
  } catch (error) {
    console.log(error);
  }
};


export const feeStructure = async () => {
  try {
    return await getData('/without-login/ed-tech/home/fee-structure');
  } catch (error) {
    console.log(error);
  }
};
// export const interviewResult = async () => {
//     try {
//         return await getData(`/job-portal/job-seeker/interview/interview`);
//     } catch (error) {
//         console.log(error);
//     }
// }
// export const getApplyedJob = async () => {
//     try {
//         return await getData(`/job-portal/job-seeker/applied-job/getuser`);
//     } catch (error) {
//         console.log(error);
//     }
// }

// export const getShortlisted = async () => {
//     try {
//         return await getData(`/job-portal/job-seeker/shortlist_jobs/shortlist`);
//     } catch (error) {
//         console.log(error);
//     }
// }

// export const saveJobPost = async (data) => {
//     try {
//         return await postData(`/job-portal/job-seeker/savejobs/savejob`, data);
//     } catch (error) {
//         console.log(error);
//     }
// };

// export const savedUserJob = async () => {
//     try {
//         return await getData(`/job-portal/job-seeker/savejobs/getsave`);
//     } catch (error) {
//         console.log(error);
//     }
// }

// export const schedule = async (data) => {
//     try {
//         return await postData(`/job-portal/job-seeker/interview/schedule`, data);
//     } catch (error) {
//         console.log(error);
//     }
// }

// export const forReject = async (data) => {
//     try {
//         console.log(data, 'dhoniiiiiiiiiiiiiiiiiiiiiiiiiiiii');
//         return await postData(`/job-portal/job-seeker/interview/schedule`, data);
//         // return await postData(`/job-portal/job-seeker/interview/reject`, data);
//     } catch (error) {
//         console.log(error);
//     }
// }

export const RegxExpression = {
  name: /[A-Z-a-z ]/,
  email: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
  phone: /^[0-9]*$/,
  year: /^\d{4}-\d{4}$/,
  percentage: /^(100(\.0{1,2})?|[1-9]([0-9])?(\.[0-9]{1,2})?|0(\.[0-9]{1,2})?)$/,
  ifsc: "^[A-Za-z]{4}[0][dA-Za-z]{6}$",
  micr: /^[0-9]{9}$/,
  bank_number: /^\d{0,14}$/,
  gst: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
};
