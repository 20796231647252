import React, { useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import '../profcymahelp/ProfcymaHelp.css'
import { ShimmerSocialPost, ShimmerThumbnail } from "react-shimmer-effects";

const ProfcymaHelp = () => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const datashimmer = [1, 2, 3, 4];

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/home/profcyma-assistance`);
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            getDataAll();
        }, 3000);
    }, []);

    return (
        <>
            <section className='profcyma-help'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='title-profcyma-help text-center mb-5'>
                                <h2 className="title">How Profcyma Help?</h2>
                                <p>Navigate success with your career counsellor. Your <br /> academic compass awaits.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {loading ? (
                            <div className="row">
                                {datashimmer.map((item, index) => (
                                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12 mt-5">
                                        <ShimmerThumbnail height={250} rounded />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            data?.map((val, index) => (
                                index % 2 === 0 ? (
                                    <div key={index} className='col-lg-3 col-md-6 col-sm-6 col-12 mt-5'>
                                        <div className='card help-card text-center'>
                                            <div className='text-holder-title-university'>
                                                <h3>{val?.name}</h3>
                                                <p className='mb-5'>{val?.sub_title}</p>
                                                <div className='main-grey-class'>
                                                    <div className='grey-backround'>
                                                        <div className='white-backround-main'>
                                                            <div className='white-backround'>
                                                                <img src={IMG_URL + val?.image} className='first-icon' alt="..." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={index} className='col-lg-3 col-md-6 col-sm-6 col-12 mt-md-5 mt-5 mb-5 mb-md-0'>
                                        <div className='card help-card text-center mt-lg-3 mt-md-3 mt-sm-3 mt-5'>
                                            <div className='text-holder-title-university'>
                                                <h3>{val?.name}</h3>
                                                <p className='mb-5'>{val?.sub_title}</p>
                                                <div className='main-grey-down-class'>
                                                    <div className='grey-backround'>
                                                        <div className='white-backround-main'>
                                                            <div className='white-backround'>
                                                                <img src={IMG_URL + val?.image} className='first-icon' alt="..." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProfcymaHelp;
