import { React, useRef, useEffect, useState, useContext } from "react";
import './Mcacard.css';
import SmallBanMca from '../SmallBanMca/SmallBanMca';
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import { ShimmerThumbnail } from "react-shimmer-effects";

function Mcacard({ id }) {
    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/courses/common/common-type/advantages/${id}`);
            setData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getDataAll(id);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [id]);

    return (
        <>
            {loading ? (                
                <ShimmerThumbnail height={250} rounded />
            ) : (
                data && data.length > 0 && (
                    <section className='mca-card-section'>
                        <div className='container'>
                            <div className='row'>
                                {data.map((val) => (
                                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12' key={val.id}>
                                        <div className='main-div'>
                                            <div className='card-div'>
                                                <div className='card-inner-div'>
                                                    <p>{parse(val.description) || ""}</p>
                                                </div>
                                            </div>
                                            <div className='shape-div'>
                                                <div className='orange-shape' />
                                            </div>
                                            <div className='shadow-div' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                )
            )}
        </>
    );
}

export default Mcacard;
