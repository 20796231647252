import React, { useState, useEffect } from 'react';
import './ReadMoreConclusion.css';
import parse from "html-react-parser";
import { ShimmerSectionHeader } from 'react-shimmer-effects';

function ReadMoreConclusion({ blogData }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading &&
      <div className='mt-5'>
          <ShimmerSectionHeader center />
      </div>
      }
      {!isLoading && (
        <section className='conclusion-section'>
          <div className='container'>
            <h1 className='headingss title'>In Conclusion,</h1>
            <p>{parse(blogData?.conclusion) || ""}</p>
          </div>
        </section>
      )}
    </>
  );
}

export default ReadMoreConclusion;
