import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "./VideoProfcyma.css";
const VideoProfcyma = () => {
  const vidRef = useRef();

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/home/video`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    vidRef.current?.play();
  }, []);

  return (
    <>
      <section className="video-profcyma">
        {data?.map((val) => (
          <div className="container">
            <div className="row">
              <video
                className="video-class-grid"
                src={IMG_URL + val?.video}
                ref={vidRef}
                muted
                loop
                controls
              />
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default VideoProfcyma;
