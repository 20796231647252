import React from 'react'
import SkillQuestions from './JP-Quetions_stepForm/SkillQuestions/SkillQuestions';
import "./StepformMain.css"

const StepformMain = () => {

    return (
        <>
            <section className='Stepformoverall'>
                <div className='container'>
                    <div className='row stepformMaincon'>
                        <div className='col-lg-7'>
                            <div>
                                <SkillQuestions

                                />
                            </div>
                        </div>
                        <div className='col-lg-5'>

                            <img src={process.env.PUBLIC_URL + "/assets/images/stepform/steformside.jpg"} className='stepformside' />
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}

export default StepformMain