import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Success from '../Animation/Success';
import './GetotpModal.css'
const GetotpModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='getotpp'
      >
        <Modal.Body>
          <div>
            <Success />
            <p className='mt-3 text-center'>{props?.message}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default GetotpModal