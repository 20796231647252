import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../../../../utils/context";
import { Container } from "react-bootstrap";
import "./Dypartners.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Dypartners = ({ id }) => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 3000); 

    return () => clearTimeout(timer); 

  }, []);

  useEffect(() => {
    if (!isLoading) {
      getDataAll(); 
    }
  }, [isLoading, id]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/common/hiring-partner/${id}`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    // autoplaySpeed: 1000,
    cssEase: "linear",
    delay: 0,
  };

  return (
    <>
      {isLoading &&
      <div className="mt-5">
          <ShimmerThumbnail height={250} rounded />
        </div> 
      } 
      {!isLoading && data && data?.length > 0 &&
        <section className="Dy-partner-section">
          <Container>
            <div>
              <div className="heading">
                <h4 className="title">Hiring Partners</h4>
                <div className="text-border" />
                <p>Our Students are Working With</p>
              </div>
              <div className="dy-partner-slider">
                <Slider {...settings} className="ggshgggfg">
                  {data?.map((item, index) => (
                    <div key={index} className="col-lg-2 col-md-4 col-sm-4 col-6 mt-4">
                      <div className="images-div">
                        <img src={IMG_URL + item.image} className="company-images" alt={`Company ${index + 1}`} />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </Container>
        </section>
      }
    </>
  );
};

export default Dypartners;
