import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import "./AboutAdvantage.css";
import MobileAdvantage from "./MobileAdvantage";

const AboutAdvantage = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/about-us/advantages`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="about-advantage">
        {/* desktop view */}
        <div className="desktop-view-about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5">
                <div className="title-profcyma-help text-center">
                  {/* <h5>Profcyma Advantages</h5>*/}
                  <h2 className="title">
                    Advantages of taking admission from Profcyma
                  </h2>
                  <p>Why Enroll In Online Learning with Profcyma</p>
                </div>
              </div>
              {data?.map((val, index) =>
                index % 2 === 0 ? (
                  <>
                    <div className="col-md-5">
                      <div className="about-title">
                        <h2>
                          <span className="main-span-class">{val?.name}</span>
                        </h2>
                        <p>{parse(val?.description) || ""}</p>
                      </div>
                    </div>
                    <div className="col-md-2 text-center">
                      <div className="circle-line-holder">
                        <div>
                          <div className="orange-main-class">
                            <div className="white-bg-class">
                              <p className="number-class">{index + 1}</p>
                            </div>
                          </div>
                          {index < data.length - 1 && (
                            <div className="middle-main text-center">
                              <div className="middle-orange-line"></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="black-small-line"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-5">
                      <div className="black-small-line"></div>
                    </div>
                    <div className="col-md-2 text-center">
                      <div className="circle-line-holder">
                        <div>
                          <div className="orange-main-class">
                            <div className="white-bg-class">
                              <p className="number-class">{index + 1}</p>
                            </div>
                          </div>
                          {index < data.length - 1 && (
                            <div className="middle-main text-center">
                              <div className="middle-orange-line"></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="about-title">
                        <h2>
                          <span className="main-span-class">{val?.name}</span>
                        </h2>
                        <p>{parse(val?.description) || ""}</p>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
        </div>
        {/* Mobile view */}
        <div className="mobile-view-about">
          <MobileAdvantage />
        </div>
      </section>
    </>
  );
};

export default AboutAdvantage;
