import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "../profcymaedge/ProfcymaEdge.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Grid, Pagination } from "swiper/modules";
import ReadMoreReact from "read-more-react";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "swiper/css/grid";
import "swiper/css";
import parse from "html-react-parser";
import { ShimmerThumbnail } from "react-shimmer-effects";
const ProfcymaEdge = () => {
  const ProfcymaEdgeDetails = [
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/home/profcyma-edge/img1.png",
      headingname: "Educate Online",
      edgetext: "We provide an extensive range of online online online online",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/home/profcyma-edge/img3.png",
      headingname: "Course Guidance",
      edgetext:
        "We offer you proper guidance in the selection selection selection",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/home/profcyma-edge/img2.png",
      headingname: "24/7 Support",
      edgetext: "Our 24/7 assistance is tailored for working working working",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/home/profcyma-edge/img4.png",
      headingname: "Specification",
      edgetext: "These Online learning courses are specifically designed  ",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/home/profcyma-edge/img1.png",
      headingname: "Educate Online",
      edgetext:
        "We provide an extensive range of online We provide an extensive range of online",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/home/profcyma-edge/img2.png",
      headingname: "24/7 Support",
      edgetext:
        "Our 24/7 assistance is tailored for working working working working",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/home/profcyma-edge/img3.png",
      headingname: "Course Guidance",
      edgetext: "We offer you proper guidance in the selection",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/home/profcyma-edge/img4.png",
      headingname: "Specification",
      edgetext: "These Online learning courses are specifically designed",
    },
  ];

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/home/stand-out`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {isLoading ? (
        <ShimmerThumbnail height={500} rounded />
      ) : (
      <section className="profcyma-edge">
        <div className="container">
          <div className="row justify-content-between ms-0 me-0 mt-5">
            <div className="col-md-4">
              <div className="profcymaedge-main-img-class mt-5 mb-md-5">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/home/profcyma-edge/women.png"
                  }
                  className="edge-img mt-5 mb-5"
                  alt="..."
                />
                <div className="headphone-main-img-class">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/home/profcyma-edge/headphone.png"
                    }
                    className="headphone-img"
                    alt="..."
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7 ms-md-0">
              <div className="title-profcyma-edge mt-5">
                {/* <h5>ProfcymaEdge</h5> */}
                <h2 className="title">Why Profcyma Stand Out</h2>
                <p>
                  Choose Profcyma for personalized career guidance, unlocking
                  your <br />
                  success with expert counselling.
                </p>
              </div>
              <div className="profcyma-edge-swiper  mb-md-5 mb-xxl-0 ">
                <Swiper
                  loop={true}
                  slidesPerView={2}
                  spaceBetween={10}
                  pagination={{
                    dynamicBullets: true,
                    clickable: true,
                  }}
                  modules={[Grid, Pagination, Autoplay]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="mySwiper"
                  breakpoints={{
                    0: {
                      slidesPerView: 1.035,
                    },
                    360: {
                      slidesPerView: 1.035,
                    },
                    567: {
                      slidesPerView: 1.035,
                    },
                    640: {
                      slidesPerView: 1.035,
                    },
                    768: {
                      slidesPerView: 1.035,
                    },
                    992: {
                      slidesPerView: 1.035,
                    },
                    1024: {
                      slidesPerView: 1.035,
                    },
                    1440: {
                      slidesPerView: 1.035,
                    },
                  }}
                >
                  {data?.map(
                    (item, index) =>

                      <SwiperSlide key={item.index}>
                        <div className="grid-container">
                          {data?.slice(index, index + 2)?.map((subItem) => (
                            <div className="row mb-md-5">
                              <div className="col-md-3">
                                <div className="alumini-main-img-class">
                                  {item.image && (
                                    <img
                                      src={IMG_URL + subItem.image}
                                      className="alumini-img"
                                      alt="..."
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-md-9">
                                <div className="test-swiper-edge">
                                  <h5>{subItem?.name}</h5>
                                  <span className="edgedescp">
                                    {parse(subItem?.description) || ""}
                                  </span>
                                  {/* <span className="readMoretxt">
                                      ...Read More
                                    </span> */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SwiperSlide>

                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      )}
    </>
  );
};

export default ProfcymaEdge;
