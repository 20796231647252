import React, { useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "./homebanner.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { ShimmerThumbnail } from "react-shimmer-effects";

const Homebanner = () => {
  const bannersliderimage = [
    {
      image: "/assets/images/home/home-banner/Home-page.png",
      title: 'Profcyma',
      subtitle: 'India’s no.1 Educational consultancy.',
      subcontent: 'Master Your Skill Learn Anytime, Anywhere'
    },
    {
      image: "/assets/images/home/home-banner/Home-page.png",
      title: 'Profcyma',
      subtitle: 'India’s no.1 Educational consultancy.',
      subcontent: 'Master Your Skill Learn Anytime, Anywhere'
    },
    {
      image: "/assets/images/home/home-banner/Home-page.png",
      title: 'Profcyma',
      subtitle: 'India’s no.1 Educational consultancy.',
      subcontent: 'Master Your Skill Learn Anytime, Anywhere'
    }
  ];

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/home/banner`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getDataAll();
    }, 3000);

    return () => clearTimeout(timer); 
  }, []);

  return (
    <>
      <section className='home-banner-section'>
        <div className='container'>
          <div className='slider-section'>
            {loading && <ShimmerThumbnail height={400} rounded />} 
            {!loading && (
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((sliderimage, index) => (
                  <SwiperSlide key={index}>
                    <div className='banner-slide-div'>
                      <div className='image-div'>
                        <img className='banner-img' src={IMG_URL + sliderimage?.image} alt={sliderimage?.title} />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Homebanner;
