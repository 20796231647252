import React from 'react'
import { createContext, useContext } from 'react';


const ImageContext = createContext();
export function MyContext({ children }) {
    const name = [

        {
            //  desktop-banner-start
            id: 7,
            image7: process.env.PUBLIC_URL + "/assets/images/about/About-US-a.png",

            id: 20,
            image20: process.env.PUBLIC_URL + "/assets/images/admission/Admission.png",


            id: 27,
            image27: process.env.PUBLIC_URL + "/assets/images/contact-us/contact-banner.png",

            //  ug-course-banner
            id: 1,
            image1: process.env.PUBLIC_URL + "/assets/images/Courses/bba-icons/BBA-Banner.png",

            id: 2,
            image2: process.env.PUBLIC_URL + "/assets/images/Courses/ba/ba-banner.png",

            id: 10,
            image10: process.env.PUBLIC_URL + "/assets/images/Courses/bsc/banner.png",

            id: 11,
            image11: process.env.PUBLIC_URL + "/assets/images/Courses/bca/onlin-banner.png",

            id: 14,
            image14: process.env.PUBLIC_URL + "/assets/images/Courses/bcom/bcom-banner.png",

            id: 22,
            image22: process.env.PUBLIC_URL + "/assets/images/Courses/blib/B.Lib.png",


            //  pg-course-banner
            id: 3,
            image3: process.env.PUBLIC_URL + "/assets/images/Courses/mcom/mcombanner.png",

            id: 8,
            image8: process.env.PUBLIC_URL + "/assets/images/Courses/MA/Mabanner.png",

            id: 18,
            image18: process.env.PUBLIC_URL + "/assets/images/Courses/msc/banner-msc.png",

            id: 9,
            image9: process.env.PUBLIC_URL + "/assets/images/Courses/mba-banner1.png",

            id: 21,
            image21: process.env.PUBLIC_URL + "/assets/images/Courses/mca/mcabanner.png",

            id: 24,
            image24: process.env.PUBLIC_URL + "/assets/images/Courses/M.Lib/mlibBannner.png",


            //  certification-course-banner
            id: 25,
            image25: process.env.PUBLIC_URL + "/assets/images/Courses/diploma/diplomabanner.png",

            //  university-banner
            id: 12,
            image12: process.env.PUBLIC_URL + "/assets/images/university/bharati-vidyapeeth/banner/ban1.png",

            id: 16,
            image16: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/banner/banner.png",

            id: 13,
            image13: process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/banner.png",

            //  blog-banner

            id: 19,
            image19: process.env.PUBLIC_URL + "/assets/images/Blogs/blogbanner.png",

            id: 23,
            image23: process.env.PUBLIC_URL + "/assets/images/Blogs/innerpages/innerbanner.png",

            id: 28,
            image28: process.env.PUBLIC_URL + "/assets/images/Blogs/Third-tab/degree-value-bannerr.png",

            id: 26,
            image26: process.env.PUBLIC_URL + "/assets/images/Blogs/second-banner.png",

            //  desktop-banner-end



            // mobile-view-banner start
            id: 29,
            image29: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/About-US.png",

            id: 30,
            image30: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Admission.png",

            id: 36,
            image36: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/ContactUs.png",

            //  blog-banner
            id: 31,
            image31: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Blog.png",

            id: 32,
            image32: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Blog3.png",

            id: 33,
            image33: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Blog1.png",

            id: 34,
            image34: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Blog2.png",

            //  certification-course-banner
            id: 35,
            image35: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Diploma.png",


            //  university-banner
            id: 37,
            image37: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/DY-Patil.png",

            id: 38,
            image38: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Bharati-Vidyapeeth.png",

            id: 39,
            image39: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Amity.png",


            //  pg-course-banner
            id: 40,
            image40: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/M.A.png",

            id: 41,
            image41: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/MBA.png",

            id: 42,
            image42: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/MCA.png",

            id: 43,
            image43: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/M.Com.png",

            id: 45,
            image45: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/M.Lib.png",

            id: 46,
            image46: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/M.Sc..png",


            //  ug-course-banner
            id: 47,
            image47: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/B.A.png",

            id: 48,
            image48: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/BBA.png",

            id: 49,
            image49: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/BCA.png",

            id: 50,
            image50: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/B.com.png",

            id: 51,
            image51: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/B.Lib.png",

            id: 52,
            image52: process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/B.Sc.png",
            // mobile-view-banner end
        }



    ]

    const online = [
        {
            bbatitle: "What is Online BBA?",
            bbacontent: "Discover the flexibility of our Online BBA program, tailored for distance learners, entrepreneurs, and working professionals. Our comprehensive curriculum ensures a solid foundation in business principles, while the convenience of online learning allows you to balance education with your busy schedule",
            mbatitle: "What is Online MBA?",
            mbacontent: "The Online MBA program extends over two years, offering a postgraduate degree accessible entirely through online platforms with no need to visit a physical campus.",

            bcomtitle: "What is Online B.Com.",
            bcomcontent: "Explore the convenience of pursuing an Online B.Com degree, tailored for distance learners, entrepreneurs, and working professionals. Our program seamlessly integrates flexible schedules, interactive virtual classrooms, and industry-relevant curriculum, empowering you to balance education with your professional commitments. Elevate your career with a comprehensive online learning experience that adapts to your lifestyle, fostering academic and professional growth. Embrace the future of education with our Online B.Com program.",

            bsctitle: "What is Online B.Sc.",
            bsccontent: "Explore boundless possibilities with our Online B.Sc program. Tailored for distance learners, entrepreneurs, and working professionals, our SEO-friendly curriculum empowers you to excel in a flexible virtual environment. Dive into interactive modules, connect with industry experts, and advance your career seamlessly. Elevate your education, embrace convenience, and unlock a world of opportunities with our Online B.Sc program. Enroll today to chart your path to success from anywhere in the world.",

            bcatitle: "What is Online BCA?",
            bcacontent: "Explore the world of Online BCA (Bachelor of Computer Applications) for distance students, entrepreneurs, and working professionals. Our program offers a flexible learning experience tailored to your schedule. Master key IT skills, enhance your entrepreneurial acumen, and boost your career—all from the convenience of your location. Elevate your knowledge in the digital realm with our comprehensive Online BCA program. Start your journey towards success today!",

            mcatitle: "What is Online MCA?",
            mcacontent: "Explore the flexibility of Online MCA (Master of Computer Applications) designed for distance learners, entrepreneurs, and working professionals. Our program seamlessly blends theoretical knowledge with real-world applications, fostering a dynamic learning environment. Stay ahead in the tech industry while balancing your career with our accessible and comprehensive Online MCA. Elevate your skills, boost your career, and embrace the future of technology education from the comfort of your own space. Join us on the journey to success!",

            mlibtitle: "What is Online M.Lib.?",
            mlibcontent: "Embark on a journey of knowledge and information management with our Online M.Lib program. Crafted to meet the needs of remote learners and working professionals, our SEO-friendly curriculum empowers you to thrive in a virtual learning environment. Immerse yourself in interactive modules, connect with industry leaders, and advance your career seamlessly. Elevate your educational experience, embrace convenience, and unlock a world of possibilities with our Online M.Lib program. Enroll today to shape your success from any corner of the globe.",
        }
    ]


    return (
        <>
            <ImageContext.Provider value={{ name, online }}>
                {children}
            </ImageContext.Provider>

        </>
    )
}

export default ImageContext;
