import React, { useState } from "react";
import "../SkillQuestions/AllQuestion.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

const Question9 = ({
  setValue,
  getValues,
  errors,
  setError,
}) => {
  const qualifications = [
    { id: "1", key: "1", label: "Post Graduate" },
    { id: "2", key: "2", label: "College Graduate" },
    { id: "3", key: "3", label: "Completed 12th" },
    { id: "4", key: "4", label: "Completed 10th" },
    { id: "5", key: "5", label: "Diploma Holder" },
  ];



  // Get the initial selected value or default to the first item
  const selectedValue = getValues("qualification_id");


  // console.log(getValues());
  return (
    <>
      <section className="Questions-sec">
        <div className="container p-0">
          <div className="StepForm-holder">
            <h3>Your Highest Qualification?</h3>
          </div>
          <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey={selectedValue}>
              <div className="row">
                <div className="col-lg-12 mx-auto">
                  <Nav variant="pills" className="justify-content-center">
                    {qualifications.map((qualification) => (
                      <Nav.Item
                        key={qualification.id}
                        className={
                          getValues("qualification_id") === qualification.label
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          setValue("qualification_id", qualification.label);
                          setError("qualification_id", "");
                        }}
                      >
                        <Nav.Link eventKey={qualification.label}>
                          {qualification.label}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
        <br />
        <div className="text-center">
          <sub className="text-danger ">{errors?.qualification_id?.message}</sub>
        </div>
      </section>
    </>
  );
};

export default Question9;
