import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../utils/context";
import './EligibiltyCriteria.css';
import { Container, Row } from 'react-bootstrap';
import parse from 'html-react-parser';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ShimmerText } from 'react-shimmer-effects';

const EligibiltyCriteria = ({ id }) => {
    const { getData } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDataAll = async () => {
        const response = await getData(`/without-login/ed-tech/specialization/eligibility/${id}`);
        setData(response?.data);
    };

    useEffect(() => {
        getDataAll();
    }, [id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading ? (
                <Container className='mt-5'>
                    <ShimmerText line={10} gap={10} />
                </Container>
            ) : (
                data && data.length > 0 && (
                    <section className='Eligibilty-Criteria'>
                        <Container>
                            <Row>
                                <div className='d-flex'>
                                    <div className='image-icon me-3'>
                                        <img className="icon-img" src={process.env.PUBLIC_URL + "/assets/images/spaecialization/criteria.png"} alt="" />
                                    </div>
                                    <div className='text-holder'>
                                        <h2>Eligibility Criteria</h2>
                                        <div className='border-line'></div>
                                    </div>
                                </div>
                                <div>
                                    <div className='bg-holder mt-4' >
                                        {data.map((d) => (
                                            <div className='d-flex ms-md-5 ms-sm-2 ms-2 mt-1' key={d.id}>
                                                <div className='me-3'>
                                                    <img className="circle-box" src={process.env.PUBLIC_URL + "/assets/images/spaecialization/Rectangle-img.png"} alt="" />
                                                </div>
                                                <div className='content-holder'>
                                                    <p>{parse(d?.description) || ""}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </section>
                )
            )}
        </>
    );
};

export default EligibiltyCriteria;
