import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";

import Container from 'react-bootstrap/Container';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import "./Testimonial.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = ({ id }) => {
    const newslider = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        cssEase: "linear"
    };

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [dataVideo, setDataVideo] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/university/common/alumani-image/${id}`);
            setData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getDataAllVideo = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/university/common/alumani/${id}`);
            setDataVideo(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleScroll = () => {
        document.querySelectorAll('.videos').forEach(video => {
            video.pause();
            video.currentTime = 0; // Reset playback time to the beginning
        });
    };

    useEffect(() => {
        getDataAll();
        getDataAllVideo();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [id]);

    return (
        <>
            {data && data.length > 0 &&
                <section className='testimonial-section'>
                    <Container>
                        <div className='heading'>
                            <h4 className='title'>Alumni Testimonials</h4>
                            <div className='text-border' />
                            <p>which will be helpful in your counselling.</p>
                        </div>

                        <div className='row'>
                            <div className='col-lg-9 col-md-9'>
                                <div className='swiper-content'>
                                    <Swiper
                                        spaceBetween={30}
                                        centeredSlides={true}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className="mySwiper"
                                    >
                                        {dataVideo.map((val, index) => (
                                            <SwiperSlide key={index}>
                                                <div className='swiper-video-div'>
                                                    <video className='videos' playsInline autoPlay muted controls >
                                                        <source src={IMG_URL + val.video} />
                                                    </video>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3'>
                                <div className='right-swiper-div'>
                                    <Slider {...newslider}>
                                        {data.map((val, index) => (
                                            <div className='bvUniversityImgMain' key={index}>
                                                <img src={IMG_URL + val.image} className='bvUniversityImg1' />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            }
        </>
    );
}

export default Testimonial;
