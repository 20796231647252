import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import "./InternationalCourse.css"
import { Button } from "react-bootstrap";
import InquiryFormModal from "../../../InquiryFormModal/InquiryFormModal";

const InternationalCourse = ({ id, universityName }) => {

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState({});

  const getDataAll = async () => {
    const response = await getData(`/without-login/ed-tech/university/common/banner-two/${id}`);
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);

  }, [id]);

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      {data && data?.length > 0 &&
        <section className='AmityInternational'>
          <div className='container'>
            <div className='internationalheadtxt'>
              <h3>{universityName}</h3>
              <div class="border-line mb-3"></div>
              <p>Offers Online Degrees + Value added international courses</p>
            </div>
            <div className='internationalbanner mb-lg-4 mb-md-0 mb-0'>
              {/* <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/sub-banner.png"} className='internationalbanner' /> */}
              <img src={IMG_URL + data?.image} />
              <div className="applybtn">
                <Button className="btn applybtn" onClick={() => setModalShow(true)} >Apply</Button>
              </div>

              < InquiryFormModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
                modalShow={modalShow}
              />
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default InternationalCourse