import React, { useEffect, useState, useContext } from "react";
import "./Mcabanner.css";
import ImageContext from "../../../../../Utils/MyContext/MyContext";
import { Context } from "../../../../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { ShimmerThumbnail } from "react-shimmer-effects";

function Mcabanner({ id, co }) {
  const { name } = useContext(ImageContext);
  const { getData, IMG_URL } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/common/common-type/banner/${id}`
      );
      setData(response?.data);
      setLoading(false); 
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); 
    }
  };

  useEffect(() => {
    setLoading(true); 
    getDataAll();
  }, [id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getDataAll();
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="mca-banner mt-4">
      <div className="container">
        <div className="row">
          <div className="col-md-12 desktop-view-banner">
            {loading ? (
              <ShimmerThumbnail height={400} rounded />
            ) : (
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="image-div">
                      <img
                        src={IMG_URL + image?.image}
                        className="bca-img"
                        alt={`Banner ${index + 1}`}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>

          <div className="col-md-12 mobile-view-banner">
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="image-div">
                    <img
                      src={IMG_URL + image?.image}
                      className="bca-img"
                      alt={`Banner ${index + 1}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Mcabanner;
