import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "./universityslider.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { ShimmerPostItem } from "react-shimmer-effects";

const Universityslider = () => {

    const unislider = [
        {
            logoimg: "/assets/images/home/primium-university/bharati.png",
            uniname: 'Bharati Vidyapeeth Pune',
            discription: 'With the changing needs of society, we all need to keep continuously upgrading ourselves.'
        },
        {
            logoimg: "/assets/images/home/primium-university/dpu.png",
            uniname: 'DPU (PUNE)',
            discription: 'Today, the institution of Dr. D. Y. Patil Vidyapeeth, Pune has created a brand name “DPU” in the field of Distance Learning.'
        },
        {
            logoimg: "/assets/images/home/primium-university/bharati.png",
            uniname: 'Bharati Vidyapeeth Pune',
            discription: 'With the changing needs of society, we all need to keep continuously upgrading ourselves.'
        },
        {
            logoimg: "/assets/images/home/primium-university/dpu.png",
            uniname: 'DPU (PUNE)',
            discription: 'Today, the institution of Dr. D. Y. Patil Vidyapeeth, Pune has created a brand name “DPU” in the field of Distance Learning.'
        },
    ];

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/university/common/all-university`);
            setData(response?.data?.data);
            console.log(response?.data?.data, "uiinin");

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            getDataAll();
        }, 3000);
    }, []);

    return (
        <>
            <section className='university-slider-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 my-auto text-center mb-4'>
                            <div className='cont-div'>
                                <p className='uni-title title'>Our Top University Partners</p>
                                <p className='uni-cont'>We are Online MBA Academic Advisors, facilitating your transition.</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='slider-div' >
                                <Swiper
                                    rewind={true}
                                    slidesPerView={1.7}
                                    spaceBetween={10}
                                    pagination={{ clickable: true }}
                                    breakpoints={{
                                        0: { slidesPerView: 1 },
                                        768: { slidesPerView: 2 },
                                        991: { slidesPerView: 3 },
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    {loading ? (
                                        Array.from({ length: 4 }).map((_, index) => (
                                            <SwiperSlide key={index}>
                                                <ShimmerPostItem
                                                    card
                                                    title
                                                    cta
                                                    imageType="thumbnail"
                                                    imageWidth={80}
                                                    imageHeight={80}
                                                    contentCenter
                                                />
                                            </SwiperSlide>
                                        ))
                                    ) : (
                                        data?.map((slidercont, index) => (
                                            <SwiperSlide key={index}>
                                                <Link 
                                                // to={`/ed-tech/university/university/${slidercont?.id}`}
                                                    to={`/ed-tech/university/${slidercont?.id}/${slidercont.name.replace(/\s+/g, '-').toLowerCase()}`}
                                                >
                                                    <div className='main-card-div'>
                                                        <div className='uni-slider'>
                                                            <div className='logo-div'>
                                                                <img className='uni-img' src={IMG_URL + slidercont?.image} alt={slidercont?.name} />
                                                            </div>
                                                            <div className='slide-content'>
                                                                <h6 className='name'>{slidercont?.name}</h6>
                                                                {/* <p className='discri'>{parse(slidercont?.description) || ""}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </SwiperSlide>
                                        ))
                                    )}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Universityslider;
