import React from 'react'
import './Conclusion.css'
function Conclusion() {
    return (
        <>
            <section className='con-section'>
                <div className='container'>
                    <div className='con-main'>
                        <div className='row'>
                            <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto'>
                                <div className='content-main'>
                                    <h2 className='title'>In Conclusions,</h2>
                                    <p>Both regular and Online MBA programs have their merits, and the best choice depends on your
                                        unique circumstances and aspirations. However, for individuals seeking a flexible,
                                        cost-effective, and inclusive path to career growth, promoting Online MBA programs emerges as a forward-thinking
                                        and accessible solution for the diverse landscape of aspiring business professionals.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12 mx-auto'>
                                <div className='second-content'>
                                    <h2 className='title'>Explore the Popular MBA Specializations</h2>
                                    <p>The MBA program offers a diverse range of specializations,
                                        available in both traditional on-campus and distance learning formats.
                                        Take a glance at the following list of MBA specializations:</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 mx-auto'>
                                <div className='table-responsive content-table'>
                                    <table className='table '>
                                        <tr className=''>
                                            <td>Accounting</td>
                                            <td>Finance</td>
                                            <td>Economics</td>
                                        </tr>
                                        <tr className=''>
                                            <td>Business Management</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className=''>
                                            <td>Entrepreneurship</td>
                                            <td>Marketing</td>
                                            <td>Human Resources Management</td>
                                        </tr>
                                        <tr className=''>
                                            <td>E-Business/E- Commerce</td>
                                            <td>Strategic/Risk Management</td>
                                            <td>Technology Management</td>
                                        </tr>
                                        <tr className=''>
                                            <td>Information Systems</td>
                                            <td>Global Management</td>
                                            <td>Operations Management</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Conclusion