import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "./aboutdpu.css";
import parse from "html-react-parser";
const Aboutdpu = ({ id, universityName }) => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/common/section-two/${id}`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data, "data data");

  useEffect(() => {
    getDataAll();

  }, [id]);

  return (
    <>
      {data && data?.length > 0 &&
        <section className="about-unidpu-sec">
          <div className="container">
            <div className="title-sec">
              <h3 className="title abt-title">{universityName}</h3>
              <div className="underline"></div>
              <p className="sub-title">
                Best Online Education University in India
              </p>
            </div>
            {/* {data?.map((val) => ( */}
            <div className="row row-topp">
              <div className="col-lg-7 my-auto">
                <div className="abtsec1">
                  <p className="cont-text">{htmlToReactParser.parse(data?.description) || ""}</p>
                  {/* <p className='cont-text'>To ensure the best quality education, <span>DPU-COL</span> gets the best academic resources for <span>Teaching, E-learning, and Self-learning</span> material from the industry. Pursuing an online MBA from DPU-COL will give you a chance to be mentored by leading academicians and industry experts. The curriculum offered is comparable to that of the leading full-time management institution. The curriculum offered for several management programs here is constantly updated as per the latest trends in the industry.</p> */}
                </div>
              </div>
              <div className="col-lg-5 ">
                <div className="abtsec2">
                  <img className="laptop-img" src={IMG_URL + data?.image} />
                </div>
              </div>
            </div>
            {/* ))} */}
          </div>
        </section>
      }

    </>
  );
};

export default Aboutdpu;
