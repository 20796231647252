import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Form from 'react-bootstrap/Form';
import './PartnerForm.css'
import { ShimmerTitle } from "react-shimmer-effects";

const PartnerForm = () => {
    const { postData } = useContext(Context);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true); // Initial loading state

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let validationErrors = {};
        if (!formData.name) {
            validationErrors.name = "Name is required";
        }
        if (!formData.email) {
            validationErrors.email = "E-mail is required";
        } else if (!validateEmail(formData.email)) {
            validationErrors.email = "Invalid E-mail format";
        }
        if (!formData.phone) {
            validationErrors.phone = "Phone number is required";
        } else if (!/^(\+\d{1,4}[-\s]?)?\d{10}$/.test(formData.phone.trim())) {
            validationErrors.phone = "Mobile number must be a 10-digit number";
        }
        if (!formData.address) {
            validationErrors.address = "Address is required";
        }
        return validationErrors;
    };

    const validateEmail = (email) => {
        const emailRegex = /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const response = await postData("/without-login/ed-tech/customer-management/become-partner", formData);
            if (response.success) {
                console.log("Submission successful:", response);
                window.location.reload(); 
            } else {
                console.error("Submission failed:", response);
               
            }
        }
    };

    function handleInputChangephone(event) {
        const inputValue = event.target.value;
        const onlyNumbers = inputValue.replace(/[^0-9]/g, '');
        const phone = onlyNumbers.slice(0, 10);
        setFormData({ ...formData, phone: phone });
    }

    function handleInputChangename(event) {
        const inputValue = event.target.value;
        const onlyLettersAndSpace = inputValue.replace(/[^a-zA-Z\s]/g, '');
        setFormData({ ...formData, name: onlyLettersAndSpace });
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); 
        }, 3000);

        return () => clearTimeout(timer); 
    }, []);

    return (
        <>
            <section>
                <div className='container'>
                    <div className="ed-techpartner-page">
                        {loading ? (
                            <ShimmerTitle line={5} gap={10} variant="primary" />
                        ) : (
                            <Form onSubmit={handleSubmit}>
                                <h1>Become A Partner</h1>
                                <div className='row'>
                                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChangename}
                                            />
                                            {errors?.name && (
                                                <span style={{ color: "red", marginLeft: "5px" }}>{errors?.name}</span>
                                            )}
                                        </Form.Group>
                                    </div>
                                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter Email Address"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                            {errors?.email && (
                                                <span style={{ color: "red", marginLeft: "5px" }}>{errors?.email}</span>
                                            )}
                                        </Form.Group>
                                    </div>
                                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Phone Number"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleInputChangephone}
                                            />
                                            {errors?.phone && (
                                                <span style={{ color: "red", marginLeft: "5px" }}>{errors?.phone}</span>
                                            )}
                                        </Form.Group>
                                    </div>
                                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label> Address</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Address"
                                                name="address"
                                                value={formData.address}
                                                onChange={handleInputChange}
                                            />
                                            {errors?.address && (
                                                <span style={{ color: "red", marginLeft: "5px" }}>{errors?.address}</span>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <button type="submit" className="submit-btn">
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default PartnerForm;
