import React, { useState } from "react";
import "../SkillQuestions/AllQuestion.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

const Question8 = ({ setValue, getValues, errors, setError }) => {
  const options = [
    { id: "1", key: "1", label: "Not Working" },
    { id: "2", key: "2", label: "Less than 3 Lac" },
    { id: "3", key: "3", label: "3 Lac to 6 Lac" },
    { id: "4", key: "4", label: "Above 6 Lacs" },
  ];



  // Get the initial selected value or default to the first item
  const selectedValue = getValues("sallary_package");

  // console.log(getValues());
  return (
    <>
      <section className="Questions-sec">
        <div className="container p-0">
          <div className="StepForm-holder">
            <h3>What is your Salary Package? (LPA)</h3>
          </div>
          <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey={selectedValue}>
              <div className="row ">
                <div className="col-lg-8 mx-auto">
                  <Nav variant="pills" className="justify-content-center">
                    {options.map((option) => (
                      <Nav.Item
                        key={option.id}
                        className={
                          getValues("sallary_package") === option.label ? "active" : ""
                        }
                        onClick={() => {
                          setValue("sallary_package", option.label);
                          setError("sallary_package", "");
                        }}
                      >
                        <Nav.Link eventKey={option.label}>
                          {option.label}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
        <br />
        <div className="text-center">
          <sub className="text-danger ">{errors?.sallary_package?.message}</sub>
        </div>
      </section>
    </>
  );
};

export default Question8;
