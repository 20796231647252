import React, { useContext, useEffect, useState } from 'react';
import './FeeStructures.css';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Context } from '../../../../../../utils/context';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ShimmerTable } from 'react-shimmer-effects';

const FeeStructures = ({ id }) => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [dataUniversity, setDataUniversity] = useState();
    const [loading, setLoading] = useState(true);

    const getDataAll = async () => {
        try {
            const response1 = await getData(`/without-login/ed-tech/home/university-specilization/${id}`);
            setDataUniversity(response1?.data?.cource_university?.university_id);
            const response = await getData(`/without-login/ed-tech/home/fee-structure/${id}`);
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            getDataAll();
        }, 3000);

        return () => clearTimeout(timer);
    }, [getData, id]);

    const shouldDisplayColumn = (key) => {
        return data.some(item => item[key] !== undefined && item[key] !== null && item[key] !== "");
    };

    const headers = [
        { key: 'sem_one', label: 'Sem I' },
        { key: 'sem_two', label: 'Sem II' },
        { key: 'sem_three', label: 'Sem III' },
        { key: 'sem_four', label: 'Sem IV' },
        { key: 'sem_five', label: 'Sem V' },
        { key: 'sem_six', label: 'Sem VI' },
        { key: 'total_fee', label: 'Total Fees' },
        { key: 'annual_fee', label: 'Annual Fees' },
        { key: 'actual_fee', label: 'Actual Fees' },
        { key: 'early_bird_discount', label: 'Early Bird Discount' },
        { key: 'discounted_one_time_fee', label: 'Discount One-time Fee' },
    ];


    return (
        <>

            {loading &&
            <Container>
                    <ShimmerTable row={1} col={5} />
            </Container>
            }
            {!loading && data && data.length > 0 &&
                <section className='Fee-structure' >
                    <Container fluid>
                        <Row>
                            <Col xxl={3} xl={3} lg={12}>
                                <div className='heading-holder'>
                                    <h2>Fee Structure</h2>
                                    <p>Degree Programme- Online Courses ({data && data[0]?.specialization?.name})</p>
                                </div>
                            </Col>
                            <Col xxl={9} xl={9} lg={12}>
                                <div className='tab-sec'>
                                    <Table bordered responsive className='text-center'>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Program Name</th>
                                                {headers.map(header => shouldDisplayColumn(header.key) && <th key={header.key}>{header.label}</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.specialization.name}</td>
                                                    {headers.map(header => item[header.key] && <td key={header.key}>{item[header.key]}</td>)}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </>
    );
};

export default FeeStructures;
