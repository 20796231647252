import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import '../alumini-hub/AluminiHub.css';
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination } from "swiper/modules";
import "swiper/css/pagination"
import "swiper/swiper-bundle.css"
import 'swiper/css';
import { ShimmerThumbnail } from "react-shimmer-effects";

const AluminiHub = () => {

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/home/our-alumni-network`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            {isLoading ? (
                <ShimmerThumbnail height={500} rounded />
            ) : (
            <section className='alumini-hub'>
                <div className='container'>
                    <div className='alumini-hub-inter'>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 mt-2 mt-lg-5 mb-lg-2 mb-xl-5 '>
                                <div className='title-profcyma-help mt-md-0 mt-lg-5'>
                                    {/* <h5>AlumniHub</h5> */}
                                    <h2>Our Alumni Network</h2>
                                    <p>Empower your journey with Profcyma's <br /> Alumni Hub—Your gateway to career <br /> success.</p>
                                </div>
                            </div>
                            <div className='col-md-6 mb-lg-3'>
                                <div className='mb-5'>
                                    <Swiper
                                        modules={[Pagination]}
                                        spaceBetween={50}
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        pagination={{
                                            dynamicBullets: true,
                                        }}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 2,
                                            },
                                            360: {
                                                slidesPerView: 2,
                                            },
                                            567: {
                                                slidesPerView: 2,
                                            },
                                            640: {
                                                slidesPerView: 2,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            992: {
                                                slidesPerView: 2,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                            },
                                            1440: {
                                                slidesPerView: 3,
                                            },
                                            2000: {
                                                slidesPerView: 3,
                                            },
                                            2560: {
                                                slidesPerView: 3,
                                            },
                                            3000: {
                                                slidesPerView: 3,
                                            },

                                        }}
                                    >
                                        {data?.map((val, index) =>
                                            <SwiperSlide>
                                                {val?.map((val1) =>
                                                    <div className='mb-5' >
                                                        <div className='alumini-main-img-class mt-5'>
                                                            <img src={IMG_URL + val1.image} className="alumini-img" alt="..." />
                                                        </div>
                                                        {/* <div className='alumini-main-img-class mt-5'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img4.png"} className="alumini-img" alt="..." />
                                                </div> */}
                                                    </div>
                                                )}
                                            </SwiperSlide>
                                        )}
                                        {/* <SwiperSlide>
                                            <div className='mb-5' >
                                                <div className='alumini-main-img-class'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img1.png"} className="alumini-img" alt="..." />
                                                </div>
                                                <div className='alumini-main-img-class mt-5'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img4.png"} className="alumini-img" alt="..." />
                                                </div>
                                            </div>
                                        </SwiperSlide> */}
                                        {/* <SwiperSlide>
                                            <div className='mb-5' >
                                                <div className='alumini-main-img-class'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img1.png"} className="alumini-img" alt="..." />
                                                </div>
                                                <div className='alumini-main-img-class mt-5'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img4.png"} className="alumini-img" alt="..." />
                                                </div>
                                            </div>
                                        </SwiperSlide> */}
                                        {/*<SwiperSlide>
                                            <div className='alumini-main-img-class'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img2.png"} className="alumini-img" alt="..." />
                                            </div>
                                            <div className='alumini-main-img-class mt-5'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img5.png"} className="alumini-img" alt="..." />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='alumini-main-img-class'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img3.png"} className="alumini-img" alt="..." />
                                            </div>
                                            <div className='alumini-main-img-class mt-5'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img6.png"} className="alumini-img" alt="..." />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='alumini-main-img-class'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img1.png"} className="alumini-img" alt="..." />
                                            </div>
                                            <div className='alumini-main-img-class mt-5'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img4.png"} className="alumini-img" alt="..." />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='alumini-main-img-class'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img2.png"} className="alumini-img" alt="..." />
                                            </div>
                                            <div className='alumini-main-img-class mt-5'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img5.png"} className="alumini-img" alt="..." />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='alumini-main-img-class'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img3.png"} className="alumini-img" alt="..." />
                                            </div>
                                            <div className='alumini-main-img-class mt-5'>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/img6.png"} className="alumini-img" alt="..." />
                                            </div>
                                        </SwiperSlide> */}
                                    </Swiper>
                                </div>
                            </div>

                            <div className='alumini-hun-backround'>
                                <img src={process.env.PUBLIC_URL + "/assets/images/home/aluminihub/aluminibg.png"} className="background-banner-img" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            )}
        </>
    )
}

export default AluminiHub;