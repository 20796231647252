import React from 'react'
import ReadThirdBanner from './ReadThirdBanner/ReadThirdBanner'
import Introduction from './Introduction/Introduction'
import CareerPath from './CareerPath/CareerPath'
import Conclusion from './Conclusion/Conclusion'

const Redmorethird = () => {
  return (
    <>
   <ReadThirdBanner/>
    <Introduction/>
    <CareerPath/>
    <Conclusion/>
    </>
  )
}

export default Redmorethird