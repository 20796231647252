import React, { useState, useEffect } from 'react';
import "./counter.css";
import CountUp from 'react-countup';
import { ShimmerThumbnail } from 'react-shimmer-effects';

const Counter = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {isLoading ? (
                <ShimmerThumbnail height={200} rounded />
            ) : (
                <section className='home-counter-section'>
                    <div className='container-md'>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <div className='counter-div'>
                                    <div className='d-flex my-auto'>
                                        <div className='icon-div'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/home/counter/education.png'} />
                                        </div>
                                        <div className='number-div my-auto'>
                                            <h4 className='count-num'>
                                                <CountUp start={0} end={99999} duration={20} decimals={0} />+
                                            </h4>
                                            <p className='count-name'>Students Trust</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='counter-div'>
                                    <div className='d-flex my-auto'>
                                        <div className='icon-div'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/home/counter/help.png'} />
                                        </div>
                                        <div className='number-div my-auto'>
                                            <h4 className='count-num'>
                                                <CountUp start={0} end={80} duration={20} decimals={0} />+
                                            </h4>
                                            <p className='count-name'>Expert Mentors</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='counter-div'>
                                    <div className='d-flex my-auto'>
                                        <div className='icon-div'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/home/counter/google.png'} />
                                        </div>
                                        <div className='number-div my-auto'>
                                            <h4 className='count-num'>4.2 /5</h4>
                                            <p className='count-name'>Google Ratings</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default Counter;
