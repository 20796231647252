import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import "./ContactForm.css";
import Form from "react-bootstrap/Form";
import AOS from 'aos';
import 'aos/dist/aos.css';
const ContactForm = () => {
  const { postData } = useContext(Context);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  console.log(errors);
  const onSubmit = async (data) => {
    try {
      const response = await postData(
        `/without-login/ed-tech/customer-management/contact-us`,
        data
      );

      console.log("response :- ", response);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <section className="contact-form">
        <div className="container">
          <div className="row" data-aos="zoom-in" data-aos-duration="3000">
            <div className="col-lg-5 col-md-6">
              <div className="forms-cont ">
                <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      name="name"
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      type="text"
                      {...register("name", {
                        required: "Name is required",
                        pattern: {
                          value: /^[A-Za-z ]*$/,
                          message: "Name must contain only letters",
                        },
                      })}
                      onKeyDown={(event) => {
                        if (!/[A-Z-a-z ]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter Full Name"
                    />
                    {errors.name && (
                      <span className="text-danger error_message">{errors.name.message}</span>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                      type="email"
                      {...register("email", {
                        required: "E-mail is required",
                        pattern: {
                          value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                          message: "Invalid E-Mail Address",
                        },
                      })}

                      placeholder="E-Mail"
                    />
                    {errors.email && (
                      <span className="text-danger error_message">
                        {errors.email.message}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.phone,
                      })}
                      type="tel"
                      {...register("phone", {
                        required: "Phone Number is required",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Phone number must be a 10-digit number",
                        },
                      })}
                      onKeyDown={(event) => {
                        const key = event.key;
                        if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Phone Number"
                      maxLength={10}
                    />
                    {errors.phone && (
                      <span className="text-danger error_message">
                        {errors.phone.message}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput4"
                  >
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.address,
                      })}
                      type="text"
                      {...register("address", {
                        required: "Address is required",
                      })}
                      placeholder="Address"
                    />
                    {errors.address && (
                      <span className="text-danger error_message">
                        {errors.address.message}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-5"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.message,
                      })}
                      as="textarea"
                      {...register("message", {
                        required: "Message is required",
                      })}
                      placeholder="Message"
                    />

                    {errors.address && (
                      <span className="text-danger error_message">
                        {errors.message.message}
                      </span>
                    )}
                    {/* <Form.Control className='text-area-holder' as="textarea" placeholder="Message" rows={3} /> */}
                  </Form.Group>
                  <div className="button-holder mb-lg-0 mb-md-5 ">
                    <button
                      type="button"
                      className="btn btn-submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Send Message
                    </button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="contact-img text-lg-end text-md-end text-center mb-lg-0 mb-md-0 mb-4">
                <img
                  className="cont-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/contact-us/img-2.png"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
