import React from 'react'
import AdmissionBanner from './AdmissionBanner/AdmissionBanner'
import AdmissionProcedure from './AdmissionProcedure/AdmissionProcedure'
import Ebrochure from './E-brochure/Ebrochure'
import RegistrationProcess from './RegistrationProcess/RegistrationProcess'
import Document from './Documents/Document'
import AdmissionPolicy from './AdmissionPolicy/AdmissionPolicy'

const Admission = () => {
  return (
    <>
      <AdmissionBanner />
      <AdmissionProcedure />
      <Ebrochure />
      <RegistrationProcess />
      <Document />
      <AdmissionPolicy />
    </>
  )
}

export default Admission