import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";



import "./OnlineLearning.css";
import parse from "html-react-parser";
import { ShimmerThumbnail } from "react-shimmer-effects";
const OnlineLearning = () => {
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const handleMainSliderClick = (direction) => {
    console.log("onclick onclick");
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const slidesContent = [
    {
      title: "Broadened Perspective",
      description:
        "Explore diverse subjects online, broadening your worldview through interactive and engaging learning experiences.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/online-learning/be-image.png",
    },
    {
      title: "Career Advancement",
      description:
        "Online learning propels careers with flexible schedules, letting professionals upskill efficiently for success.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/online-learning/be-image.png",
    },
    {
      title: "Time Management",
      description:
        "Master time with online courses, balancing life and education seamlessly for personalized, efficient learning.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/online-learning/be-image.png",
    },

    {
      title: "Broadened Perspective",
      description:
        "Explore diverse subjects online, broadening your worldview through interactive and engaging learning experiences.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/online-learning/be-image.png",
    },
    {
      title: "Career Advancement",
      description:
        "Online learning propels careers with flexible schedules, letting professionals upskill efficiently for success.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/online-learning/be-image.png",
    },
    {
      title: "Time Management",
      description:
        "Explore diverse subjects online, broadening your worldview through interactive and engaging learning experiences.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/online-learning/be-image.png",
    },
  ];

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/home/online-learning`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <ShimmerThumbnail height={500} rounded />
      ) : (
      <section className="online-learning">
        <div className="container-fluid">
          <div className="row">
            <div className="heading-holder text-center">
              {/* <h5>Online Learning</h5> */}
              <h4 className="title">
                Advantages of Online Learning for working professionals
              </h4>
              <p>
                Unlock career triumph with Profcyma: Elevate skills, embrace
                convenience; conquer goals online
              </p>
            </div>
            <div className="online-learning-slider">
              <Swiper

                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={10}

                breakpoints={{
                  '0': {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  '575': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  '768': {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  '991': {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  '1200': {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },

                  '1400': {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}

                className="mySwiper"
              > 
                {data?.map((slide, index) => (
                  <SwiperSlide >
                    <div className="learning-heading mb-5">
                      <img className="online-girl" src={IMG_URL + slide.image} alt="..." />
                      <div className="text-overlay">
                        <h6 className="title">{slide.name}</h6>
                        <p>{parse(slide.description)}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

          </div>
        </div>
      </section>
      )}
    </>
  );
};

export default OnlineLearning;
