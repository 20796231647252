import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import "./Questions.css";
import parse from "html-react-parser";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Questions = ({ id }) => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);  

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/common/all-faq/${id}`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTimeout(() => {  
        setLoading(false);
      }, 3000);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, [id]);

  return (
    <>
      {loading ? (  
        <Container>
          <ShimmerThumbnail height={450} rounded />
        </Container>
      ) : (
        data && data?.length > 0 && (
          <section className="question-section">
            <Container>
              <div className="heading">
                <h4 className="title">Frequently Asked Questions</h4>
                <div className="text-border" />
              </div>
              <div className="questions">
                <Accordion defaultActiveKey="0">
                  {data?.map((val, index) => (
                    <Accordion.Item key={index} eventKey={index.toString()}>
                      <Accordion.Header>{val?.name}</Accordion.Header>
                      <Accordion.Body>
                        <p>{parse(val?.description)}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </Container>
          </section>
        )
      )}
    </>
  );
};

export default Questions;
