import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Form from 'react-bootstrap/Form';
import "./becomepartner.css";

const BecomePartner = () => {

  const { postData } = useContext(Context);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",

  });
  const [errors, setErrors] = useState();

  const handleInputChange = (event) => {

    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const validateForm = () => {

    let errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.email) {
      errors.email = "E-mail is required";
    }
    else if (!validateEmail(formData.email)) {
      errors.email = "Invalid E-mail format";
    }

    if (!formData.phone) {
      errors.phone = "Phone  no. is required";
    } else if (
      !/^(\+\d{1,4}[-\s]?)?\d{10}$/.test(formData.phone.trim())
    ) {
      errors.phone =
        "Mobile No must be a 10-digit number";
    }

    if (!formData.address) {
      errors.address = "Address is required";
    }

    return errors;
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  }

  const validateEmail = (email) => {
    const emailRegex =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return emailRegex.test(email);
  };
  const usedDomains = new Set();



  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const response = await postData("/without-login/ed-tech/customer-management/become-partner", formData);
      if (response.success) {
        console.log("response", response);
        window.location.reload();
      } else {
        console.log("response", response);
      }
    }

  };

  function handleInputChangephone(event) {
    const inputValue = event.target.value;
    const onlyNumbers = inputValue.replace(/[^0-9]/g, '');
    const phone = onlyNumbers.slice(0, 10);
    setFormData({
      ...formData,
      phone: phone
    });
  }

  function handleInputChangename(event) {
    const inputValue = event.target.value;
    const onlyLetters = inputValue.replace(/[^a-zA-Z]/g, '');

    setFormData({
      ...formData,
      name: onlyLetters
    });
  }

  return (
    <div className="become-partner-modal-form">
      <form>
        <div className="mb-3">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleInputChangename}
          />
          {errors?.name && (
            <span style={errorStyle}>{errors?.name}</span>
          )}
        </div>
        <div>
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        {errors?.email && (
          <span style={errorStyle}>{errors?.email}</span>
        )}
        <div className="mb-3 ">
          <input
            className="form-control phpnejhbwjc"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleInputChangephone}


          />
          {errors?.phone && (
            <span style={errorStyle}>{errors?.phone}</span>
          )}
        </div>

        <div className="mb-3">
          <input
            className="form-control phpnejhbwjc"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleInputChange}
          />
          {errors?.address && (
            <span style={errorStyle}>{errors?.address}</span>
          )}
        </div>
        <div className="text-center">
          <button type="submit" className="submit-btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default BecomePartner;
