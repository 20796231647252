import React, {useEffect} from 'react'
import './ContactMap.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
const ContactMap = () => {
    useEffect(() => {
        AOS.init({ once: true });
    }, []);
    return (
        <>
            <section className='contact-map' data-aos="fade-up" data-aos-duration="3000">
                <div className='container'>
                    <div className='row' >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.1877885133745!2d73.76940577579694!3d18.52041416917794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be5a439e4855%3A0xa27b14514af0a4b3!2sProfcyma%20Career%20Solutions%20LLP%7COnline%20MBA%20Programs%7COnline%20Degree%20Programs%7CDistance%20MBA%7CBest%20Online%20Education%20Service%20Provider%7C!5e0!3m2!1sen!2sin!4v1707554528149!5m2!1sen!2sin" 
                        width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </>

    )
}

export default ContactMap