import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";

import Container from 'react-bootstrap/Container';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import "./Benifits.css";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Benifits = ({ id }) => {
    const swiperRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/university/common/student-benefit/${id}`);
            setData(response?.data);
            setLoading(false); 
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false); 
        }
    };

    useEffect(() => {
        setLoading(true); 
        setTimeout(() => {
            getDataAll();
        }, 3000); 
    }, [id]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const handleSlideChange = (swiper) => {
        document.querySelectorAll('.benifit-videos').forEach((video, index) => {
            if (index === swiper.activeIndex) {
                video.play();
            } else {
                video.pause();
                video.currentTime = 0;
            }
        });
    };

    const handleScroll = () => {
        document.querySelectorAll('.benifit-videos').forEach(video => {
            video.pause();
            video.currentTime = 0;
        });
    };

    return (
        <>
            {loading ? (
                <div className="shimmer-wrapper">
                    <ShimmerThumbnail height={250} rounded />
                </div>
            ) : (
                data && data.length > 0 &&
                <section className='DY-benifits-section'>
                    <Container>
                        <div className='heading'>
                            <h4 className='title'>Students Benefits</h4>
                            <div className='text-border' />
                        </div>
                        <div className='benifit-slider'>
                            <Swiper
                                key={data.length}
                                effect={'coverflow'}
                                grabCursor={true}
                                centeredSlides={true}
                                initialSlide={1}
                                coverflowEffect={{
                                    rotate: 50,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 1,
                                    slideShadows: false,
                                }}
                                pagination={true}
                                modules={[EffectCoverflow, Pagination]}
                                slideShadows={false}
                                className="mySwiper"
                                onSlideChange={handleSlideChange}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    769: {
                                        slidesPerView: 3,
                                    },
                                }}
                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                            >
                                {data.map((val, index) =>
                                    <SwiperSlide key={index}>
                                        <div className='swiper-video'>
                                            <video className='benifit-videos' playsInline muted controls>
                                                <source src={IMG_URL + val?.video}></source>
                                            </video>
                                            <div className='video-border' />
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                            <div
                                className="main-slider"
                                onClick={() => handleMainSliderClick("prev")}
                            >
                                <div className="arrow-back" />
                            </div>
                            <div
                                className="main-slider"
                                onClick={() => handleMainSliderClick("next")}
                            >
                                <div className="arrow-next" />
                            </div>
                        </div>
                    </Container>
                </section>
            )}
        </>
    );
}

export default Benifits;
