import React, { useContext, useState } from "react";
import "./FirstStep.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useEffect } from "react";
import { allCourseType } from "../../../../../../utils/apis/common/Common";
import { Context } from "../../../../../../utils/context";
library.add(fas);

function FirstStep({ setValue, getValues, errors, setError }) {
  const { IMG_URL } = useContext(Context);
  const [course, setCourse] = useState();

  const getCourse = async () => {
    const res = await allCourseType();
    if (res?.success) {
      setCourse(res.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  console.log(getValues());
  return (
    <>
      <search className="QuestionsStepFrom">
        <div className="container p-0">
          <div className="StepForm-holder">
            <h3>Which Degree are You Interested in?</h3>
          </div>

          <div>


            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <div className="row ">
                <div className="col-lg-12 mx-auto">
                  <Nav variant="pills" className="aluminifirstrow">
                    {course?.map((item) => (
                      <Nav.Item key={item?.id}>
                        <Nav.Link
                          eventKey={item?.id}
                          className={
                            getValues("course_type_id") === item?.id
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            setValue("course_type_id", item?.id);
                            setError("course_type_id", "");
                          }}
                        >
                          {item?.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </div>
            </Tab.Container>
          </div>
          <div className="text-center">
            <sub className="text-danger ">
              {errors?.course_type_id?.message}
            </sub>
          </div>
        </div>
      </search>
    </>
  );
}

export default FirstStep;
