import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import "./KeyPrograme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import parse from "html-react-parser";
import { ShimmerThumbnail } from "react-shimmer-effects";

const KeyPrograme = ({ id }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/common/program-features/${id}`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [id]);

  console.log(data);

  return (
    <>
      {loading ? (
        <ShimmerThumbnail height={250} rounded />
      ) : (
        data && data.length > 0 && (
          <section className="key-program">
            <div className="container">
              <div className="row">
                <div className="heading-holder text-center">
                  <h5>Key Program Features</h5>
                  <div className="border-line mb-3"></div>
                  <p>
                    Engaging, stimulating—learning that enriches your educational
                    journey profoundly.
                  </p>
                </div>
              </div>

              <div className="row">
                <Swiper
                  spaceBetween={15}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    486: {
                      slidesPerView: 2,
                    },
                    578: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                    1400: {
                      slidesPerView: 5,
                    },
                    1600: {
                      slidesPerView: 5,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {data.map((val) => (
                    <SwiperSlide key={val.id}>
                      <div className="col-md-12 mt-5">
                        <Card className="mb-5">
                          <div className="image-holder mt-5 text-center">
                            <img
                              src={IMG_URL + val.image}
                              className="amity-img"
                              alt={val.name}
                            />
                          </div>
                          <Card.Body>
                            <Card.Title>
                              <div className="name-holder">
                                <h4>{val.name}</h4>
                              </div>
                              <div className="midlle">
                                <div className="text text-center">
                                  <p className="p-2">
                                    {parse(val.description)}
                                  </p>
                                </div>
                              </div>
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section>
        )
      )}
    </>
  );
};

export default KeyPrograme;
