import React from 'react'
import FirstBanner from './FirstBanner/FirstBanner'
import OfflineOnline from './OfflineOnline/OfflineOnline'
import UpgradeCareer from './UpgradeCareer/UpgradeCareer'
import Conclusion from './Conclusion/Conclusion'
import FAQs from './FAQs/FAQs'

const Readmorefirst = () => {
  return (
    <>
    <FirstBanner/>
    <OfflineOnline/>
    <UpgradeCareer/>
    <Conclusion/>
    <FAQs/>
    </>
  )
}

export default Readmorefirst