import React from 'react'
import ContactBanner from './Contact-Banner/ContactBanner'
import ContactForm from './Contact-form/ContactForm'
import ContactMap from './Contact-map/ContactMap'

const ContactUs = () => {
  return (
    <>
      <ContactBanner/>
      <ContactForm/>
      <ContactMap/>
    </>
  )
}

export default ContactUs