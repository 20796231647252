import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./Media.css";
import { Autoplay, Navigation } from "swiper/modules";
import { ShimmerThumbnail } from "react-shimmer-effects";
const Media = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/home/media-function`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {isLoading ? (
        <ShimmerThumbnail height={600} rounded />
      ) : (
      <section className="media-proficiency">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-lg-1 order-sm-2 order-2">
              <div className="media_proficiency_slider">
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={25}
                  modules={[Navigation, Autoplay]}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      // spaceBetween: 10,
                    },
                    360: {
                      slidesPerView: 1,
                      //   spaceBetween: 130,
                    },
                    486: {
                      slidesPerView: 2,
                      //   spaceBetween: 50,
                    },
                    578: {
                      slidesPerView: 2,
                      //   spaceBetween: 130,
                    },
                    768: {
                      slidesPerView: 3,
                      //   spaceBetween: 250,
                    },

                    1024: {
                      slidesPerView: 2.5,
                      //   spaceBetween: 200,
                    },
                    1200: {
                      slidesPerView: 2.5,
                      //   spaceBetween: 100,
                    },
                    1300: {
                      slidesPerView: 3,
                      //   spaceBetween: 220,
                    },

                    1440: {
                      slidesPerView: 2.6,
                      //   spaceBetween: 150,
                    },
                  }}
                  //   autoplay={{
                  //     delay: 2500,
                  //     disableOnInteraction: false,
                  //     pauseOnMouseEnter: true,
                  //     loop: true,
                  //   }}
                  className="mySwiper"
                >
                  {data?.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <div className="paper-imgs">
                        <img
                          src={IMG_URL + slide.image}
                          className="award-img"
                          alt="..."
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="overlay_layer"></div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-2 order-sm-1 order-1">
              <div className="heading-holder">
                {/* <h5>Media Proficiency</h5> */}
                <h4 className="title">Profcyma In Media</h4>
                <p>
                  Unlock success with Profcyma, Pune's choice for career counselling.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      )}
    </>
  );
};

export default Media;
