import React from 'react'
import './OfflineOnline.css'
function OfflineOnline() {
    const onlinedata = [
        {
            title: "Schedule Flexibility",
            text: "One of the most compelling reasons to opt for an Online MBA is the flexibility it offers. Working professionals, parents, and individuals with busy schedules can design their study routines around their commitments. The asynchronous nature of online courses allows students to access lectures and complete assignments at their convenience."

        },
        {
            title: "No Relocation Required",
            text: "Online MBA programs eliminate the need for relocation, making education accessible to individuals regardless of their geographical location. This is particularly advantageous for those who are unable or unwilling to uproot their lives for a traditional on-campus program."
        },
        {
            title: "Reliable Internet Access",
            text: "For an Online MBA, a stable internet connection is paramount. As long as you have reliable internet access, you can participate in classes, collaborate with peers, and engage in discussions seamlessly, breaking down geographical barriers."
        },
        {
            title: "Strong Written Communication",
            text: "Online MBA programs often place a strong emphasis on written communication. Through discussion forums, assignments, and virtual collaboration, students hone their written communication skills, a valuable asset in the digital age of business."
        },
        {
            title: "Self-Discipline",
            text: "The self-paced nature of Online MBA programs demands a high level of self-discipline. Students must manage their time effectively, stay organized, and maintain motivation to succeed in a virtual learning environment."
        },
        {
            title: "Cost Consideration",
            text: "Online MBA programs are often more cost-effective than their Regular counterparts. Students can save on commuting, accommodation, and related expenses. Many online programs also allow students to continue working while pursuing their degrees, providing a practical way to offset tuition costs."
        },
    ];

    const regulardata = [
        {
            title: "Structured Environment",
            text: "A Regular MBA offers a structured learning environment with fixed class schedules, in-person lectures, and a well-defined curriculum. This structured approach can be beneficial for individuals who thrive in a traditional classroom setting."

        },
        {
            title: "In-person Interaction",
            text: "Face-to-face interaction with professors and peers fosters a dynamic learning environment. In Regular MBA programs, students can engage in immediate discussions, ask questions, and benefit from the in-person guidance of experienced educators."
        },
        {
            title: "Networking Opportunities",
            text: "Regular MBA programs often provide extensive networking opportunities through on-campus events, group projects, and collaborative activities. Building a robust professional network is crucial for career growth, and the traditional classroom setting facilitates these connections."
        },
        {
            title: "Facilities and Resources",
            text: "On-campus MBA programs provide access to state-of-the-art facilities, libraries, and resources that enhance the learning experience. Students have direct access to physical libraries, research materials, and other campus amenities."
        },
        {
            title: "Greater Recognition",
            text: "While the perception of online education is changing, some employers still value a Regular MBA from a well-known institution more highly. The reputation of the institution can play a significant role in the perceived value of the degree."
        },
        {
            title: "Vibrant Campus Life",
            text: "For those seeking a holistic university experience, a Regular MBA offers a vibrant campus life. Extracurricular activities, clubs, and events contribute to personal development and a well-rounded educational journey."
        },
        {
            title: "Career Switching",
            text: "Individuals looking to make a significant career switch may find the structured environment of a Regular MBA program more conducive to making industry connections, accessing career services, and participating in recruiting events."
        },
    ];
    return (
        <>
            <section className='onlineoffline-section'>
                <div className='container'>
                    <div className='off-on-main'>
                        <div className='row '>
                            <div className='col-9 mx-auto'>
                                <div className='off-on-contents'>
                                    <h2 className='title'>Online MBA vs Regular MBA : Which One is Best For You</h2>
                                    <p>In the fast-paced world of business, the decision to pursue a Master of Business Administration (MBA) is
                                        a significant step toward career advancement. However, the choice between a Regular MBA and an Online MBA
                                        can be a challenging decision. In this comprehensive guide, we'll delve into the characteristics of each program,
                                        exploring the factors that can help you make an informed decision tailored to your career goals.</p>

                                </div>
                            </div>
                        </div>
                        <div className='tabs-contents'>
                            <div className='button-div'>
                                <ul className="nav nav-pills mb-3 justify-content-center " id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><div className='button1'><img src='/assets/images/Blogs/innerpages/buttonlogo1.png' className='button1-logo' /></div><span className='buttonspan'>Online</span> MBA</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><div className='button1'><img src='/assets/images/Blogs/innerpages/buttonlogo2.png' className='button1-logo' /></div><span className='buttonspan'>Offline</span> MBA</button>
                                    </li>

                                </ul>
                            </div>

                            <div className='mainconts'>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div className='conts'>
                                            <div className='conts-heading'>
                                                <h3 className='title'>Why Choose a Online MBA?</h3>
                                            </div>

                                            <div className='conts-contents'>
                                                <div className='row justify-content-center'>
                                                    {onlinedata?.map((data) => (
                                                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 '>
                                                            <div className='cardmain-div'>
                                                                <div class="card ">
                                                                    <div className="card-body">
                                                                        <div className="card-title">
                                                                            <h5 className='datatitle'>{data.title}</h5>
                                                                        </div>
                                                                        <div className="card-text">
                                                                            <p>{data.text}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div className='conts'>
                                            <div className='conts-heading'>
                                                <h3 className='title'>Why Choose a Regular MBA?</h3>
                                            </div>
                                            <div className='conts-contents'>
                                                <div className='row justify-content-center'>
                                                    {regulardata?.map((data) => (
                                                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 '>
                                                            <div className='cardmain-div'>
                                                                <div class="card">
                                                                    <div className="card-body">
                                                                        <div className="card-title">
                                                                            <h5 className='datatitle'>{data.title}</h5>
                                                                        </div>
                                                                        <div className="card-text">
                                                                            <p>{data.text}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OfflineOnline