import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./InquiryFormModal.css";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { getData, postData } from "../../../utils/api";
import SubitFormModal from "../Admission/E-brochure/SubitFormModal/SubitFormModal";
import Cookies from "js-cookie"; // Import js-cookie
import closebutton from "js-cookie";

const InquiryFormModal = (props) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [coursesdata, setCoursesdata] = useState([]);
    const [modalsuccess, setModalsuccess] = useState(false);

    useEffect(() => {
        // Check if the form was already submitte

        const getcoursesdata = async () => {
            try {
                const res = await getData('/without-login/ed-tech/courses/common/common-type/courses');
                if (res?.success) {
                    setCoursesdata(res?.data);
                }
            } catch (error) {
                console.error("Error fetching courses data:", error);
            }
        };

        getcoursesdata();
    }, []);

    const onSubmit = async (data) => {
        try {
            const res = await postData('/without-login/ed-tech/inquiry', data);
            if (res?.success) {
                console.log("Response:", res);
                Cookies.set('inquirySubmitted', true, { expires: 7 });
                console.log(Cookies.get("inquirySubmitted"), "Form submission tracked");
                reset();
                setModalsuccess(true);
                setTimeout(() => {
                    setModalsuccess(false);
                }, 3000);
                props.onHide();
            } else {
                console.error("Error response:", res);
            }
        } catch (error) {
            console.error("Error submitting inquiry:", error);
        }
    };

    return (
        <>
            <Modal
                className="inquiry-form-modal modal-dialog-scrollable"
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                // backdrop="static"
                show={props.show}
                onHide={props.onHide}
            >
                <Modal.Header className="modal-header-centered" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Inquiry Form
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-sec-start">
                        <div className="row">
                            <span>*Please fill the details below completely</span>
                        </div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="form-detail">
                                <Col md={6}>
                                    <Form.Group controlId="formGridname" className="mb-3">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            name="name"
                                            className={classNames("", {
                                                "is-invalid": errors?.name,
                                            })}
                                            type="text"
                                            {...register("name", {
                                                required: "Name is required",
                                                pattern: {
                                                    value: /^[A-Za-z ]*$/,
                                                    message: "Name must contain only letters",
                                                },
                                            })}
                                            onKeyDown={(event) => {
                                                if (!/[A-Z-a-z ]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            placeholder="Enter Full Name"
                                        />

                                        {errors?.name && <div className="invalid-feedback">{errors.name.message}</div>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formGridphone" className="mb-3">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control
                                            className={classNames("", {
                                                "is-invalid": errors?.contact_no,
                                            })}
                                            type="tel"
                                            {...register("contact_no", {
                                                required: "Contact No Number is required",
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Contact No number must be a 10-digit number",
                                                },
                                                maxLength: 10
                                            })}
                                            onKeyDown={(event) => {
                                                const key = event.key;
                                                if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            placeholder="Contact Number"
                                            maxLength={10}
                                        />
                                        {errors?.contact_no && <div className="invalid-feedback">{errors.contact_no.message}</div>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formGridemail" className="mb-3">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            className={classNames("", { "is-invalid": errors?.email })}
                                            type="email"
                                            {...register("email", {
                                                required: "E-mail is required",
                                                pattern: {
                                                    value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                                    message: "Invalid E-Mail Address",
                                                },
                                            })}
                                            placeholder="E-Mail"
                                        />
                                        {errors?.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formGridcourse" className="mb-3">
                                        <Form.Label>Courses</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            className={classNames("", { "is-invalid": errors?.course_id })}
                                            {...register("course_id", { required: "Please select a Course" })}
                                        >
                                            <option value="">Select a course...</option>
                                            {coursesdata?.map((item, index) => (
                                                <option key={index} value={item?.id}>
                                                    {item?.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {errors?.course_id && <div className="invalid-feedback">{errors.course_id.message}</div>}
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="formGridmessage" className="mb-3">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            className={classNames("", { "is-invalid": errors?.message })}
                                            {...register("message", { required: "Message is required" })}
                                            as="textarea"
                                            rows={3}
                                        />
                                        {errors?.message && <div className="invalid-feedback">{errors.message.message}</div>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="button-holder text-center mb-5">
                                <Button className="btn apply-btn me-3" type="submit">
                                    Apply
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
            {modalsuccess && (
                <SubitFormModal
                    show={modalsuccess}
                    onHide={() => setModalsuccess(false)}
                />
            )}
        </>
    );
};

export default InquiryFormModal;