import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../utils/context";
import BlogsBanner from './BlogsBanner/BlogsBanner'
import MbaDetails from './MbaDetails/MbaDetails'

function Blogs() {
  const { getData, IMG_URL } = useContext(Context);
  const [blogData, setBlogData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(`/without-login/ed-tech/blog/blogs`);
    await setBlogData(response?.data?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(blogData);

  }, []);
  return (
    <>
      <BlogsBanner blogData={blogData} />
      <MbaDetails blogData={blogData} />

    </>
  )
}

export default Blogs