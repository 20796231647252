import React, { useState, useEffect } from 'react';
import "./Applybanner.css";
import InquiryFormModal from '../../../../InquiryFormModal/InquiryFormModal';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { Container } from 'react-bootstrap';

const Applybanner = () => {
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); 

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading ? (
                <Container>
                    <ShimmerThumbnail height={400} rounded />
                </Container>
            ) : (
                <section className='Applybanner'>
                    <div className='container'>
                        <div className='Applyimgmain' onClick={() => setModalShow(true)}>
                            <img src={process.env.PUBLIC_URL + "/assets/images/Courses/Banner-apply-Now.jpg"} className='applybanner' alt="Apply Now Banner" />
                            <div className='applymainrow'></div>
                        </div>
                    </div>
                </section>
            )}
            <InquiryFormModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
                modalShow={modalShow}
            />
        </>
    );
}

export default Applybanner;
