import React, { useContext, useEffect, useState } from "react";
import "../SkillQuestions/AllQuestion.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Context } from "../../../../../utils/context";
import { allCourseByType } from "../../../../../utils/apis/common/Common";

const Question1 = ({ setValue, getValues, errors, setError, setSelectedCourse }) => {

  const { IMG_URL } = useContext(Context);

  const [allcourses, setAllCourse] = useState();

  const getAllCourse = async () => {
    const res = await allCourseByType(getValues("course_type_id"));
    if (res?.success) {
      setAllCourse(res.data);
      // Set the default selectedId here
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllCourse();
  }, []);

  // console.log(getValues());
  return (
    <>
      <section className="Questions-sec">
        <div className="container p-0">
          <div className="StepForm-holder">
            <h3>Which Course You would like to Pursue?</h3>
            <p>Select a Desired Course</p>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <div className="row ">
              <div className="col-lg-12 mx-auto">
                <Nav variant="pills" className="aluminifirstrow">
                  {allcourses?.map((item) => (
                    <Nav.Item key={item?.id}>
                      <Nav.Link
                        eventKey={item?.id}
                        className={
                          getValues("course_id") === item?.id ? "active" : ""
                        }
                        onClick={() => {
                          setValue("course_id", item?.id);
                          setError("course_id", "");
                          setSelectedCourse(item);
                        }}
                      >
                        <div className="aluminipara">
                          <div className="text-center">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/home/OurAlumini/alustepimg.png"
                              }
                              className="alustepimg"
                            />
                          </div>
                          <p>{item?.name}</p>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
            </div>
          </Tab.Container>
        </div>
        <div className="text-center">
          <sub className="text-danger ">{errors?.course_id?.message}</sub>
        </div>
      </section>
    </>
  );
};

export default Question1;
